import React, { useEffect, useMemo, useState } from 'react'
import { URL_SERVER_API } from '../../appConfig';
import ChuyenBan from './ChuyenBan';
import ModalContainer from '../../components/Modal/ModalContainer';
import useShowToast from '../../hooks/useShowToast';
import GopBan from './GopBan';

const DanhSachBanVaKhuVuc = ({ onSelectBan = () => { }, onReloadOrder = true, onReloadChuyenBan = () => { } }) => {
    const [valueBan, setvalueBan] = useState([])
    const [banSelect, setBanSelect] = useState('')

    const [listBan, setListBan] = useState([])

    const [showChuyenBan, setShowChuyenBan] = useState(false)
    const [banCanChuyen, setBanCanChuyen] = useState({})

    const [showGopBan, setShowGopBan] = useState(false)
    const [reload, setReload] = useState(false)


    const showToast = useShowToast()

    useEffect(() => {
        async function getValue() {
            try {
                const result = await fetch(`${URL_SERVER_API}/v1/ban/filter?limit=1000&offset=0`, { method: 'GET' });

                if (!result.ok) {
                    alert("đã có lỗi nghiêm trọng");
                    return;
                }

                const resultJson = await result.json();

                // Kiểm tra nếu không có dữ liệu hợp lệ
                if (!resultJson || !resultJson.value || !resultJson.value.data) {
                    alert("Dữ liệu không hợp lệ hoặc không có dữ liệu");
                    return;
                }

                const dsKvVsBan = resultJson.value.data;

                // Cập nhật danh sách bàn
                setListBan(dsKvVsBan);

                // Kiểm tra nếu danh sách bàn trống
                if (!Array.isArray(dsKvVsBan) || dsKvVsBan.length === 0) {
                   
                    return;
                }

                // Nhóm dữ liệu theo 'tenKhuVuc'
                const groupKhuVuc = dsKvVsBan.reduce((acc, item) => {
                    const key = item["tenKhuVuc"];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});

                // Chuyển đổi thành mảng và sắp xếp từng nhóm theo tên bàn
                const groupedData = Object.entries(groupKhuVuc).map(([khuVuc, banList]) => {
                    // Sắp xếp các bàn theo tên bàn
                    const sortedBanList = banList.sort((a, b) => {
                        const numA = parseInt(a.tenBan.replace('Bàn ', '')) || 0;
                        const numB = parseInt(b.tenBan.replace('Bàn ', '')) || 0;
                        return numA - numB;
                    });

                    return [khuVuc, sortedBanList];
                });

                // Cập nhật giá trị bàn đã được nhóm
                setvalueBan(groupedData);
            } catch (error) {
                console.error("Lỗi khi gọi API:", error);
                alert("Có lỗi xảy ra khi gọi API");
            }
        }

        getValue();
    }, [onReloadOrder, reload]);



    return (
        <>

            <div className='bg-light mt-2'>
                <div>
                    <button className='ui label tiny orange mb-1' onClick={() => {
                        if (!banSelect) {
                            showToast("warning", "Hãy chọn bàn trước khi chuyển!")
                            return;
                        }
                        const banSL = listBan.find(item => item.maBan === banSelect)
                        setBanCanChuyen(banSL)
                        if (banSL.trangThai === 1) {
                            showToast("warning", "Chỉ bàn đã order mới được chuyển!")
                            return;
                        }
                        setShowChuyenBan(!showChuyenBan)
                    }}>Chuyển bàn</button>
                    <button className='ui label tiny violet mb-1' onClick={() => {
                        if (!banSelect) {
                            showToast("warning", "Hãy chọn bàn trước khi gọp!")
                            return;
                        }
                        const banSL = listBan.find(item => item.maBan === banSelect)
                        setBanCanChuyen(banSL)
                        if (banSL.trangThai === 1) {
                            showToast("warning", "Chỉ bàn đã order mới gọp!")
                            return;
                        }
                        if (banSL.trangThai === 4) {
                            showToast("warning", "Đã in hóa đơn bàn này!")
                            return;
                        }
                        setShowGopBan(!showGopBan)
                    }}>Gọp bàn</button>
                    <button className='ui label tiny pink mb-1'>Tách bàn</button>
                </div>
                <div className='mb-1 mt-2'>
                    <span className='ui label mini mb-1'>Tróng</span>
                    <span className='ui label mini green mb-1'>Đã order</span>
                    <span className='ui label mini yellow mb-1'>Đã in chế biến</span>
                    <span className='ui label mini blue mb-1'>Đã in bill</span>
                </div>
                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {
                        valueBan.map(([khuVuc, banList], index) => {
                            return (
                                <div key={index}>
                                    <div className='form-label h6 '>{khuVuc}</div>
                                    <div className='mb-2'>
                                        {banList.map((item, index) => {

                                            const getColor = (trangThai) => {
                                                switch (trangThai) {
                                                    case 2: return "green";
                                                    case 3: return "yellow";
                                                    case 4: return "blue";
                                                    default: return "";
                                                }
                                            };

                                            return (
                                                <button
                                                    key={index}
                                                    onClick={() => {
                                                        onSelectBan(item);
                                                        setBanSelect(item.maBan);
                                                    }}
                                                    className={`m-1 ui button mini ${getColor(item.trangThai)} ${banSelect === item.maBan ? 'active' : ''}`}
                                                    style={{ borderRadius: '5px' }}
                                                >
                                                    {item.tenBan}
                                                </button>
                                            );
                                        })}

                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

            </div>
            {showChuyenBan && (<ModalContainer><ChuyenBan onReload={() => {
                setReload(!reload)
                onReloadChuyenBan()
            }} banCanChuyen={banCanChuyen} onClose={() => setShowChuyenBan(false)} /></ModalContainer>)}
            {showGopBan && (<ModalContainer><GopBan onReload={() => {
                setReload(!reload)
                onReloadChuyenBan()
            }} banCanChuyen={banCanChuyen} onClose={() => setShowGopBan(false)} /></ModalContainer>)}

        </>
    )
}

export default DanhSachBanVaKhuVuc
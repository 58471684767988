import React, { useCallback } from 'react';

const Qr = ({ nganHang = "", soTaiKhoan = "", chuTaiKhoan = "", soTien = "", maHoaDonShort = "", onClose = () => { } }) => {
    // Hàm xử lý đóng modal
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    // Hàm ngăn chặn sự kiện lan ra bên ngoài
    const handleStopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);

    // Xây dựng URL cho ảnh QR Code
    const qrCodeUrl = `https://img.vietqr.io/image/${nganHang}-${soTaiKhoan}-compact2.jpg?amount=${soTien}&addInfo=${maHoaDonShort}%20Thanh%20Toan&accountName=${chuTaiKhoan}`;

    // Kiểm tra nếu có đầy đủ thông tin thì mới hiển thị
    const isValidQrData = nganHang && soTaiKhoan && chuTaiKhoan && soTien && maHoaDonShort;

    // Thành phần hiển thị QR Code
    const QrCodeImage = () => (
        <img
            onClick={handleStopPropagation}
            className='mt-5 qr-image'
            style={{ objectFit: 'contain' }}
            src={qrCodeUrl}
            alt="QR Code"
        />
    );


    // Phần hiển thị chính của component
    return (
        <div 
            className='position-absolute top-0 start-50 z-3 mt-5 qr-container' 
            style={{ transform: 'translateX(-50%)' }} 
            onClick={handleClose}
        >
            {isValidQrData ? (
                <>
                    <QrCodeImage />
                    <button className="btn-close" onClick={handleClose}>Đóng</button>
                </>
            ) : (
                <p>Thông tin không đầy đủ để tạo QR code.</p>
            )}
        </div>
    );
};

export default Qr;

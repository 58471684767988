import React from 'react';
const padZero = (num) => (num < 10 ? `0${num}` : num);
const useFormatDate = () => {
    const formatDate = (dateString, format) => {
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        const day = padZero(date.getDate());
        const month = padZero(date.getMonth() + 1);
        const year = date.getFullYear();
        const hours = padZero(date.getHours());
        const minutes = padZero(date.getMinutes());
        const seconds = padZero(date.getSeconds());
        switch (format) {
            case 'dd-MM-yyyy':
                return `${day}-${month}-${year}`;
            case 'dd-MM-yyyy hh:mm:ss':
                return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
            case 'yyyy-MM-dd':
                return `${year}-${month}-${day}`;
            case 'yyyy-MM-dd hh:mm:ss':
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            default:
                return `${day}-${month}-${year}`;
        }
    };

    return formatDate;
};

export default useFormatDate;

import React from 'react'
import { Link } from 'react-router-dom'

const ListButtonAdmin = () => {
  return (
    <div>
      <div className="divider">Đặt quyền (Admin)</div>

      <li className="nav-item mt-2">
        <Link to="/ql_ch_he_thong"><button className="btn btn-outline-secondary w-100" style={{ fontSize: "1.2rem" }} data-bs-dismiss="offcanvas" id="btn_thong_tin_he_thong">
          Cấu hình hệ thống
        </button></Link>
      </li>

      <li className="nav-item mt-2">
        <Link to="/ql_kieu_tt"><button className="btn btn-outline-warning w-100" style={{ fontSize: "1.2rem" }} id="btn_ql_pt_tt" data-bs-dismiss="offcanvas">
          Quản lý phương thức thanh toán
        </button></Link>
      </li>

      <li className="nav-item mt-2">
        <a href="/"><button className="btn btn-outline-primary w-100" style={{ fontSize: "1.2rem" }} id="btn_ql_tkAll" data-bs-dismiss="offcanvas">
          Quản lý tài khoản
        </button></a>
      </li>

      <li className="nav-item mt-2">
        <a href="/"><button className="btn btn-outline-dark w-100" style={{ fontSize: "1.2rem" }} data-bs-dismiss="offcanvas" id="btn_ql_vai_tro">
          Quản lý loại tài khoản
        </button></a>
      </li>


      <li className="nav-item mt-2">
        <a href="/"><button className="btn btn-outline-secondary w-100" style={{ fontSize: "1.2rem" }} data-bs-dismiss="offcanvas" id="btn_ql_thong_ke">
          Thống kê
        </button></a>
      </li>

      <li className="nav-item mt-2">
        <a href="/"><button className="btn btn-outline-info w-100" style={{ fontSize: "1.2rem" }} data-bs-dismiss="offcanvas" id="btn_ql_nk_ht">
          Nhật ký hệ thống
        </button></a>
      </li>


      <div className="divider opacity-25">-</div>

    </div>
  )
}

export default ListButtonAdmin
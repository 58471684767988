import React, { useEffect, useRef } from 'react'
import Header from './layouts/Header'
// import Footer from './layouts/Footer'
import Navigation from './layouts/Navigation'
import { BrowserRouter } from 'react-router-dom'
import Router from './layouts/Router'
import { useIsLogin } from './contexts/IsLoginContext'
import Login from './page/Login'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const App = () => {

  const navRef = useRef(null);
  const { isLoggedIn } = useIsLogin();

  const handleDivClick = () => {

    if (navRef.current) {
      navRef.current.handleEndShowNav();
    }
  };

  const urlCheck = window.location.pathname

  // useEffect(() => {

  //   if (urlCheck === "/") {
  //     window.location.href = "man_hinh_thu_ngan"
  //   }
  // }, [urlCheck])






  return (
    <BrowserRouter>
      {isLoggedIn ? (
        <>
          <Header />
          <div className='pt-5 d-flex' style={{ height: '100vh' }}>
            <Navigation ref={navRef} />
            <div className='p-2 w-100 h-100 overflow-hidden overflow-y-scroll ' onClick={handleDivClick}>
              <Router />
            </div>
          </div>
          <ToastContainer />
        </>
      ) :
        (
          <Login />
        )
      }

    </BrowserRouter>
  )
}

export default App
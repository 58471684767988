import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';

const IsLoginContext = createContext();

export const IsLoginProvider = ({ children }) => {
    // Khởi tạo trạng thái đăng nhập
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    

    // Kiểm tra token từ cookie khi ứng dụng khởi động
    useEffect(() => {
        const token = Cookies.get('tookenAccount'); // Kiểm tra cookie 'tookenAccount'
        if (token) {
            setIsLoggedIn(true); // Nếu có token, đặt trạng thái là đăng nhập
        }
    }, []);

    // Hàm đăng nhập (có thể thêm logic lưu token vào cookie)
    const login = (token) => {
        Cookies.set('tookenAccount', token, { expires: 1 });// Lưu token vào cookie
        setIsLoggedIn(true);
    };

    // Hàm đăng xuất (xóa token từ cookie)
    const logout = () => {
        Cookies.remove('tookenAccount'); // Xóa cookie khi đăng xuất
        setIsLoggedIn(false);
    };

    return (
        <IsLoginContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </IsLoginContext.Provider>
    );
};

// Custom hook để sử dụng context
export const useIsLogin = () => {
    return useContext(IsLoginContext);
};

export default IsLoginContext;

import React, { Fragment, useState, useMemo, useCallback } from 'react';
import './table.min.css';
import './menu.min.css';
import { URL_SERVER } from '../../appConfig';

const TableElm = ({
    optionButton = [{}],
    TableConfig = [{}],
    valueTable = [{}],
    totalItems = 0,
    TableGroup = '',
    onHandleClickBtn = () => { },
    onHandleSelectLimit = () => { },
    onHandleChangeTxt = () => { },
    onHandlePagination = () => { }
}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const totalPages = useMemo(() => {
        return Math.ceil(totalItems / limit);
    }, [totalItems, limit]);

    const handleClickBtn = useCallback((value, type) => {
        onHandleClickBtn(value, type);
    }, [onHandleClickBtn]);

    const handleSelectLimit = useCallback((value) => {
        onHandleSelectLimit(value);
        setLimit(value);
        setCurrentPage(1);
    }, [onHandleSelectLimit]);

    const handleChangeTxt = useCallback((value) => {
        onHandleChangeTxt(value);
    }, [onHandleChangeTxt]);

    const handlePagination = useCallback((page, type) => {
        if (type === 'next') {
            if (page < totalPages) {
                setCurrentPage(page + 1);
                onHandlePagination(page + 1);
            }
        } else if (type === 'prev') {
            if (page > 1) {
                setCurrentPage(page - 1);
                onHandlePagination(page - 1);
            }
        } else if (type === 'direct') {
            if (page >= 1 && page <= totalPages) {
                setCurrentPage(page);
                onHandlePagination(page);
            }
        }
    }, [totalPages, onHandlePagination]);

    const groupValue = useMemo(() => {
        if (!TableGroup) return { 'default': valueTable }; 
        return valueTable.reduce((acc, item) => {
            const key = item[TableGroup] || 'Ungrouped';
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
    }, [TableGroup, valueTable]);

    const groupedData = useMemo(() => Object.entries(groupValue), [groupValue]);

    return (
        <Fragment>
            <div className='row d-flex align-items-center'>
                <div className="col-md-6">
                    <label htmlFor="limit">Số dòng hiển thị</label>
                    <select className='ms-2 me-1' id="limit" onChange={(e) => handleSelectLimit(Number(e.target.value))}>
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                    / {totalItems} bản ghi
                </div>
                <div className='col-md-6'>
                    <input onChange={(e) => handleChangeTxt(e.target.value)} type="search" className='form-control w-100' placeholder="Tìm kiếm..." style={{ borderRadius: "5px" }} />
                </div>
                
            </div>

            <table className="ui celled padded table">
                <thead>
                    <tr>
                        <th style={{ width: "25px" }}>Stt</th>
                        {TableConfig.map((item, index) => !item.hidden && (
                            <Fragment key={index}>
                                <th>{item.title}</th>
                            </Fragment>
                        ))}
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {groupedData.map(([groupKey, groupItems], groupIndex) => (
                        <Fragment key={groupIndex}>
                            {TableGroup && (
                                <tr className="table-group-header">
                                    <td colSpan={TableConfig.length + 2}>
                                        <strong>{groupKey}</strong>
                                    </td>
                                </tr>
                            )}
                            {groupItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    {TableConfig.filter(config => !config.hidden).map((config, idx) => {
                                        const value = item[config.name];
                                        const isImage = typeof value === 'string' && (value.endsWith('.jpg') || value.endsWith('.jpeg') || value.endsWith('.png'));
                                        return (
                                            <td key={idx}>
                                                {isImage ? (
                                                    <img
                                                        src={`${URL_SERVER}/uploads/images/${value}`}
                                                        alt="image"
                                                        style={{ width: '70px', height: 'auto' }}
                                                    />
                                                ) : (
                                                    value
                                                )}
                                            </td>
                                        );
                                    })}

                                    <td style={{ width: "20px" }}>
                                        <div className='d-flex'>
                                            {optionButton && optionButton.map((itemBtn, btnIndex) => (
                                                <button
                                                    onClick={() => handleClickBtn(item, itemBtn.type)}
                                                    className={`ui button mini ${itemBtn.type === "delete" ? "red" : itemBtn.type === "update" ? "orange" : "blue"}`}
                                                    key={btnIndex}
                                                >
                                                    {itemBtn.title}
                                                </button>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                </tbody>

                <tfoot>
                    <tr>
                        <th colSpan={TableConfig.length + 2}>
                            <div className="ui right floated pagination menu">
                                <span
                                    className={`icon item ${currentPage === 1 ? 'disabled' : ''}`}
                                    onClick={() => handlePagination(currentPage - 1, 'direct')}
                                >
                                    <i className="fa fa-arrow-left"></i>
                                </span>
                                {Array.from({ length: 3 }, (_, i) => {
                                    let startPage = Math.max(1, currentPage - 1);
                                    let page = startPage + i;
                                    if (page <= totalPages) {
                                        return (
                                            <span
                                                key={page}
                                                className={`item ${currentPage === page ? 'active' : ''}`}
                                                onClick={() => handlePagination(page, 'direct')}
                                            >
                                                {page}
                                            </span>
                                        );
                                    }
                                    return null;
                                })}
                                <span
                                    className={`icon item ${currentPage === totalPages ? 'disabled' : ''}`}
                                    onClick={() => handlePagination(currentPage + 1, 'direct')}
                                >
                                    <i className="fa fa-arrow-right"></i>
                                </span>

                            </div>

                        </th>
                    </tr>
                </tfoot>

            </table>
        </Fragment>
    );
};

export default TableElm;

import React, { useEffect, useState, useMemo } from 'react';
import { URL_SERVER_API } from '../../appConfig';
import Qr from './Qr';

const TotalBill = ({ ban = {}, order = {}, onReloadOrder = true, onValueTotal = {}, totalBill = {}, onSelectPay = () => { } }) => {
    const [kieuThanhToan, setKieuThanhToan] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [kieuTTSelect, setKieuTTSelect] = useState({});
    const [tongHoaDon, setTongHoaDon] = useState(0);
    const [chietKhau, setChietKhau] = useState('0');
    const [tienKhachDua, setTienKhachDua] = useState('0');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showOr, setShowOr] = useState(false)


    useEffect(() => {
        if (typeof onValueTotal === 'function') {
            onValueTotal({
                tongHoaDon: tongHoaDon,
                chietKhau: chietKhau,
                tongSauChietKhau: getTotalAfterDiscount,
                tienKhachDua: tienKhachDua,
                tienThuaTraKhach: getChangeForCustomer
            });
        }
    }, [tongHoaDon, chietKhau, tienKhachDua, ban]);


    useEffect(() => {
        const getPaymentTypes = async () => {
            try {
                setLoading(true);
                const result = await fetch(`${URL_SERVER_API}/v1/ktt/filter?limit='2000'&offset='0'`);
                const resultJson = await result.json();
                console.log("🚀 ~ getPaymentTypes ~ resultJson:", resultJson)
                
                if (resultJson.status === 'successfully' ) {
                    if(resultJson.value.data !== "error"){
                        setKieuThanhToan(resultJson.value.data);
                    }
                   
                }
                setLoading(false);
            } catch (err) {
                setError("Không thể tải các kiểu thanh toán");
                setLoading(false);
            }
        };
        getPaymentTypes();
    }, []);

    useEffect(() => {
        const fetchTotalBill = async () => {
            try {
                const result = await fetch(`${URL_SERVER_API}/v1/thungan/tongctorder/${ban.maBan}`);
                const resultJson = await result.json();
                if (result.ok) {
                    setTongHoaDon(resultJson || 0);
                } else {
                    setTongHoaDon(0);
                }
            } catch (err) {
                setError("Không thể tải tổng hóa đơn");
                setTongHoaDon(0);
            }
        };

        if (ban?.maBan) {
            fetchTotalBill();
        }
    }, [onReloadOrder, ban]);

    const handleSelect = (value) => {
        const selectedPayment = kieuThanhToan.find(ktt => ktt.maKieuTT === value);
        if (selectedPayment) {
            setKieuTTSelect(selectedPayment);
            onSelectPay(selectedPayment)
            setShowDetail(!!selectedPayment.soTaiKhoan);
        } else {
            setShowDetail(false);
        }
    };

    const handleNumberInput = setter => e => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setter(value);
        }
    };

    const getTotalAfterDiscount = useMemo(() => {
        const discountValue = parseFloat(chietKhau) || 0;
        return tongHoaDon - (tongHoaDon * (discountValue / 100));
    }, [chietKhau, tongHoaDon]);

    const tongChietKhau = useMemo(() => {
        const discountValue = parseFloat(chietKhau) || 0;
        return (tongHoaDon * (discountValue / 100));
    }, [chietKhau, tongHoaDon]);

    const getChangeForCustomer = useMemo(() => {
        const moneyGivenByCustomer = parseFloat(tienKhachDua) || 0;
        const totalAfterDiscount = getTotalAfterDiscount;

        if (!tienKhachDua || moneyGivenByCustomer === 0) {
            return 0; // Nếu không có tiền khách đưa
        } else if (moneyGivenByCustomer < totalAfterDiscount) {
            return `Cần thêm ${(totalAfterDiscount - moneyGivenByCustomer).toLocaleString()} đ`;
        } else {
            return ` ${(moneyGivenByCustomer - totalAfterDiscount).toLocaleString()} đ`;
        }
    }, [tienKhachDua, getTotalAfterDiscount]);

    if (loading) {
        return <div>Đang tải dữ liệu...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>

            <div className='row' style={{ minHeight: '200px' }}>
                <div className='col-md-4'>
                    <select onChange={(e) => handleSelect(e.target.value)} className='form-select p-1'>
                        <option value="">Thanh toán tiền mặt</option>
                        {kieuThanhToan.map((item) => (
                            <option key={item.maKieuTT} value={item.maKieuTT}>
                                {item.tenKieuTT}
                            </option>
                        ))}
                    </select>
                    {showDetail && (
                        <div>
                            <div style={{ maxWidth: '300px', textWrap: 'nowrap', overflowX: 'auto' }} className='custom-scrollbar mt-1 mb-1'>
                                <strong>Ngân hàng: </strong>{kieuTTSelect.nganHang}
                            </div>
                            <div style={{ maxWidth: '300px', textWrap: 'nowrap', overflowX: 'auto' }} className='custom-scrollbar mb-1'>
                                <strong>Số tài khoản: </strong>{kieuTTSelect.soTaiKhoan}
                            </div>
                            <div style={{ maxWidth: '300px', textWrap: 'nowrap', overflowX: 'auto' }} className='custom-scrollbar mb-1'>
                                <strong>Chủ tài khoản: </strong>{kieuTTSelect.chuTaiKhoan}
                            </div>
                            <button className='ui button mini violet' onClick={() => setShowOr(true)}>Hiển thị Qr</button>
                        </div>
                    )}
                </div>

                <div className='col-md-8 mt-1 rounded'>
                    <div className="mb-1">
                        <strong className='d-inline-block' style={{ width: "150px" }}>Tổng hóa đơn: </strong>
                        <input
                            type="text"
                            className="form-control d-inline-block w-50 p-1"
                            disabled
                            value={Number(tongHoaDon).toLocaleString()}
                        />
                    </div>

                    <div className="mb-1">
                        <strong className='d-inline-block' style={{ width: "150px" }}>Chiết khấu (%): </strong>
                        <input
                            type="text"
                            className="form-control d-inline-block me-3 p-1"
                            style={{ width: '55px' }}
                            value={chietKhau}
                            onChange={handleNumberInput(setChietKhau)}
                        />
                        <span>=&gt;</span> <span>{tongChietKhau.toLocaleString()} đ</span>
                    </div>

                    <div className="mb-1">
                        <strong className='d-inline-block' style={{ width: "150px" }}>HD sau chiết khấu: </strong>
                        <input
                            type="text"
                            className="form-control d-inline-block w-50 p-1"
                            disabled
                            value={getTotalAfterDiscount.toLocaleString()}
                        />
                    </div>

                    <div className="mb-1">
                        <strong className='d-inline-block' style={{ width: "150px" }}>Tiền khách đưa: </strong>
                        <input
                            type="text"
                            className="form-control d-inline-block w-50 p-1"
                            value={tienKhachDua}
                            onChange={handleNumberInput(setTienKhachDua)}
                        />
                    </div>

                    <div className="mb-1">
                        <strong className='d-inline-block' style={{ width: "150px" }}>Tiền trả khách: </strong>
                        <input
                            type="text"
                            className="form-control d-inline-block w-50 p-1"
                            disabled
                            value={getChangeForCustomer}
                        />
                    </div>
                </div>
            </div>

            {
                showOr && (
                    <Qr
                        onClose={() => setShowOr(false)}
                        chuTaiKhoan={kieuTTSelect.chuTaiKhoan}
                        maHoaDonShort="AbcTesst"
                        soTaiKhoan={kieuTTSelect.soTaiKhoan}
                        nganHang={kieuTTSelect.nganHang}
                        soTien={getTotalAfterDiscount}
                    />
                )
            }

        </>
    );
};

export default TotalBill;

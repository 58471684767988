import React, { forwardRef, useState } from 'react';
import "./navigation.css";
import { Link } from 'react-router-dom'

const Navigation = forwardRef((props, ref) => {

    const [closeNav, setCloseNav] = useState(true);
    const [activeItem, setActiveItem] = useState('btn_man_hinh_thu_ngan'); 

    const handleItemClick = (itemId) => {
        setActiveItem(itemId);

        let titleText;
        switch (itemId) {
            case "btn_man_hinh_thu_ngan":
                titleText = "Màn hình thu ngân";
                break;
            case "btn_ql_ban_hang":
                titleText = "Quản lý bán hàng";
                break;
            case "btn_ql_kv_ban":
                titleText = "Khu vực, Bàn";
                break;
            case "btn_ql_san_pham":
                titleText = "Sản phẩm";
                break;
            case "btn_ql_tt_kh":
                titleText = "Thông tin khách hàng";
                break;
            default:
                titleText = "";
        }

        document.getElementById('title-header').innerHTML = titleText;
    };

    const handleEndShowNav = () => {
        setCloseNav(true);
    };

    // Gán handleEndShowNav cho ref để component cha có thể gọi
    React.useImperativeHandle(ref, () => ({
        handleEndShowNav,
    }));


    return (
        <div onClick={(e) => e.stopPropagation()} className={`bg-danger h-100 pt-2 ${closeNav ? 'navigation-end' : 'navigation-show'}`}>
            <div className={`p-2 d-flex ${closeNav ? 'justify-content-center' : 'justify-content-end'}`}>
                {closeNav ? (
                    <i
                        className="fa fa-bars cursor-pointer"
                        onClick={() => setCloseNav(false)}
                        style={{ fontSize: '1.7rem', color: 'white' }}
                    ></i>
                ) : (
                    <i
                        className="fa-solid  fa-xmark-large cursor-pointer"
                        onClick={() => setCloseNav(true)}
                        style={{ fontSize: '1.7rem', color: 'white' }}
                    ></i>
                )}
            </div>

            <ul className="list-unstyled mt-2" id="navigation">
                <li className={`menu-item d-flex align-items-center justify-content-between ${activeItem === 'btn_man_hinh_thu_ngan' ? 'active' : ''}`} id="btn_man_hinh_thu_ngan">
                    <Link to="/man_hinh_thu_ngan" onClick={() => handleItemClick('btn_man_hinh_thu_ngan')} className="d-flex align-items-center justify-content-between w-100 text-decoration-none">
                        <span className="menu-text me-2 text-nowrap" style={{ color: 'white' }}>Màn hình thu ngân</span>

                        <i className="fa-regular fa-cash-register" style={{ fontSize: '1.5rem', color: 'white' }}></i>
                    </Link>
                </li>
                <li className={`menu-item d-flex align-items-center justify-content-between ${activeItem === 'btn_ql_ban_hang' ? 'active' : ''}`} id="btn_ql_ban_hang">
                    <Link to="/ql_ban_hang" onClick={() => handleItemClick('btn_ql_ban_hang')} className="d-flex align-items-center justify-content-between w-100 text-decoration-none">
                        <span className="menu-text me-2 text-nowrap" style={{ color: 'white' }}>Quản lý bán hàng</span>
                        <i className="fa-sharp fa-solid fa-note" style={{ fontSize: '1.5rem', color: 'white' }}></i>
                    </Link>
                </li>
                <li className={`menu-item d-flex align-items-center justify-content-between ${activeItem === 'btn_ql_kv_ban' ? 'active' : ''}`} id="btn_ql_kv_ban">
                    <Link to="/ql_kv_ban" onClick={() => handleItemClick('btn_ql_kv_ban')} className="d-flex align-items-center justify-content-between w-100 text-decoration-none">
                        <span className="menu-text me-2 text-nowrap" style={{ color: 'white' }}>Khu vực, Bàn</span>
                        <i className="fa fa-table" style={{ fontSize: '1.5rem', color: 'white' }}></i>
                    </Link>
                </li>
                <li className={`menu-item d-flex align-items-center justify-content-between ${activeItem === 'btn_ql_san_pham' ? 'active' : ''}`} id="btn_ql_san_pham">
                    <Link to="/ql_san_pham" onClick={() => handleItemClick('btn_ql_san_pham')} className="d-flex align-items-center justify-content-between w-100 text-decoration-none">
                        <span className="menu-text me-2 text-nowrap" style={{ color: 'white' }}>Sản phẩm</span>
                        <i className="fa-solid fa-burger-soda" style={{ fontSize: '1.5rem', color: 'white' }}></i>
                    </Link>
                </li>
                <li className={`menu-item d-flex align-items-center justify-content-between ${activeItem === 'btn_ql_tt_kh' ? 'active' : ''}`} id="btn_ql_tt_kh">
                    <Link to="/ql_tt_kh" onClick={() => handleItemClick('btn_ql_tt_kh')} className="d-flex align-items-center justify-content-between w-100 text-decoration-none">
                        <span className="menu-text me-2 text-nowrap" style={{ color: 'white' }}>Thông tin khách hàng</span>
                        <i className="fa-light fa-user-secret" style={{ fontSize: '1.5rem', color: 'white' }}></i>
    
                    </Link>
                </li>
            </ul>

        </div>
    );
})

export default Navigation;

import React, { useEffect, useState } from 'react';
import DanhSachBanVaKhuVuc from './MangHinhThuNgan/DanhSachBanVaKhuVuc';
import DanhSachMon from './MangHinhThuNgan/DanhSachMon';
import ChiTietDatHang from './MangHinhThuNgan/ChiTietDatHang';
import TuyChonKhachHang from './MangHinhThuNgan/TuyChonKhachHang';
import ButtonHandleBill from './MangHinhThuNgan/ButtonHandleBill';
import TotalBill from './MangHinhThuNgan/TotalBill';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../appConfig';
import useTokenAccount from '../hooks/useTokenAccount';
import taoMaHoaDon from '../libs/taoMaHoaDon';
import Cookies from 'js-cookie';

const MangHinhThuNganPlus = () => {
    const token = useTokenAccount()
    const [banDaChon, setBanDaChon] = useState({})
    const [duLieuOrder, setDuLieuOrder] = useState({})
    const [activeTab, setActiveTab] = useState('banVsKV')
    const [selectKhach, setSelectKhach] = useState({})
    const [reloadOrder, setReloadOrder] = useState(true)
    const [valueTotal, setValueTotal] = useState({})
    const [maHoaDon, setMaHoaDon] = useState('')
    const [selectPay, setSelectPay] = useState({})
    const [maHoaDonSortSet, setMaHoaDonSortSet] = useState();
    const [reloadChuyenBan, setReloadChuyenBan] = useState(false)

    const handleOrder = async (dataSelect, monSelect) => {
        const order = dataSelect

        order.maMon = monSelect
        const result = await fetch(`${URL_SERVER_API}/v1/thungan/order`, {
            method: 'POST',
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                maBan: banDaChon.maBan,
                maSp: order.maMon,
                sl: order.soLuongSP,
                ghiChu: order.ghiChuSP
            })
        });

        const maTam = await fetch(`${URL_SERVER_API}/v1/thungan/laymatam/${banDaChon.maBan}`, { method: 'GET' });
        if (maTam.ok) {
            const maTamJson = await maTam.json()
            if (!maTamJson) {
                await fetch(`${URL_SERVER_API}/v1/thungan/setmahoadonngan`, {
                    method: 'PUT',
                    headers: HEADER_TOKEN_API(token),
                    body: JSON.stringify({
                        maBan: banDaChon.maBan,
                        maTam: maHoaDon
                    })
                });
            }

        }



        const resultJson = await result.json()

        setDuLieuOrder(order)
        setReloadOrder(!reloadOrder)
    }



    useEffect(() => {
        const maHoaDonSort = taoMaHoaDon()
        const maHDTam = maHoaDonSort + "-" + Cookies.get('maTaiKhoanSort')

        async function layDuLieu() {
            try {
                const maTam = await fetch(`${URL_SERVER_API}/v1/thungan/laymatam/${banDaChon.maBan}`, { method: 'GET' });
                if (maTam.ok) {
                    const maTamJson = await maTam.json()
                    if (maTamJson) {
                        setMaHoaDonSortSet(maTamJson)
                    }
                    else {
                        setMaHoaDonSortSet(maHDTam)
                    }
                }
                else {
                    setMaHoaDonSortSet(maHDTam)
                }
            } catch (error) {
                setMaHoaDonSortSet(maHDTam)
            }
        }
        layDuLieu()
    }, [banDaChon])


    

    return (
        <div className="row p-2 bg-light" >
            <div className='col-md-6'>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <span className={`nav-link cursor-pointer ${activeTab === "banVsKV" && 'active'}`} onClick={() => setActiveTab("banVsKV")}>Chọn bàn</span>
                    </li>
                    <li className="nav-item">
                        <span className={`nav-link cursor-pointer ${activeTab === "spMon" && 'active'}`} onClick={() => setActiveTab("spMon")}>Order</span>
                    </li>
                    <li className="nav-item d-lg-none">
                        <span className={`nav-link cursor-pointer ${activeTab === "spMon" && 'active'}`} onClick={() => setActiveTab("total")}>chi tiết Order</span>
                    </li>

                </ul>

                {
                    activeTab === "banVsKV" && (
                        <DanhSachBanVaKhuVuc onReloadChuyenBan={()=>setReloadOrder(!reloadOrder)} onSelectBan={(ban) => { setBanDaChon(ban) }} onReloadOrder={reloadOrder} />
                    )
                }

                {
                    activeTab === "spMon" && (
                        <DanhSachMon maHoaDonSort={maHoaDonSortSet} ban={banDaChon} isBanDaChon={Object.keys(banDaChon).length > 0} onOrderMon={(dataSelect, monSelect) => handleOrder(dataSelect, monSelect)} />
                    )
                }
            </div>

            <div className="col-md-6">
                <ChiTietDatHang maHoaDonSort={maHoaDonSortSet} ban={banDaChon} order={duLieuOrder} onReloadOrder={reloadOrder} sendReload={() => setReloadOrder(!reloadOrder)} />
                <hr />
                <TuyChonKhachHang onHandleSelect={(kh) => { setSelectKhach(kh) }} />
                <TotalBill onSelectPay={(e) => setSelectPay(e)} ban={banDaChon} order={duLieuOrder} onReloadOrder={reloadOrder} onValueTotal={(value) => { setValueTotal(value) }} />
                <ButtonHandleBill
                    maHoaDonSort={maHoaDonSortSet}
                    ban={banDaChon}
                    order={duLieuOrder}
                    onReloadOrder={reloadOrder}
                    sendReload={() => setReloadOrder(!reloadOrder)}
                    khachSelect={selectKhach}
                    totalBill={valueTotal}
                    selectPay={selectPay}
                />
            </div>
        </div>
    );
};

export default MangHinhThuNganPlus;

import React, { useEffect, useState, useRef } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import BillCheBien from '../../components/Bill/BillCheBien';
import { useReactToPrint } from 'react-to-print';
import useTokenAccount from '../../hooks/useTokenAccount';
import Bill from '../../components/Bill/Bill';
import useFormatDate from '../../hooks/useFormatDate';
import useShowToast from '../../hooks/useShowToast';

const ButtonHandleBill = ({ maHoaDonSort = "", ban = {}, order = {}, onReloadOrder = true, sendReload = () => { }, khachSelect = {}, totalBill, selectPay }) => {
    const token = useTokenAccount()
    const showToast = useShowToast()

    const [cuahang, setCuaHang] = useState({
        logoBase64: "",
        tenCuahang: "",
        sdtCuuaHang: "",
        diaChiCuahang: "",
        maSoThue: ""
    });

    const [reload, setReload] = useState(false);
    const [configBill, setConfigBill] = useState(null);
    const [inforUser, setInforUser] = useState({})
    const [inCheBien, setInCheBien] = useState(false)

    const formatDate = useFormatDate()

    const [valueOrder, setValueOrder] = useState([]);

    const [valueCheBien, setValueCheBien] = useState({
        maBan: '',
        ban: '',
        thoiGianIn: '',
        sanPham: []
    });
    const [valueBill, setValueBill] = useState({
        thoiGianIn: new Date().toLocaleString(),
        maHoaDon: "*****",
        tenNhanVien: "*****",
        sdtKhach: "*****",
        tenKhach: "*****",
        sanPham: [
            { tenSanPham: "*****", soLuong: 0, donGia: 0 },
            { tenSanPham: "*****", soLuong: 0, donGia: 0 }
        ],
        chietKhau: 0,
        tongHoaDon: 0,
        ban: {
            tenBan: "",
            tenKhuVuc: ""
        },
        dlChuyenKhoan: {
            nganHang: "",
            soTaiKhoan: "",
            chuTaiKhoan: ""
        }
    })

    const componentRef = useRef(null);
    const componentRefBill = useRef(null);

    const handlePrintCheBien = useReactToPrint({
        contentRef: componentRef,
        documentTitle: 'HoaDonCheBien',
        onAfterPrint: async () => {

            // cập nhật trạng thái hóa đơn
            await fetch(`${URL_SERVER_API}/v1/thungan/capnhattrangthai`, {
                method: 'PUT',
                headers: HEADER_TOKEN_API(token),
                body: JSON.stringify({
                    maBan: ban.maBan,
                    trangThai: 3
                })
            });


            // cập nhật trạng thái món hiện tại
            valueOrder.forEach(async order => {
                if (order.trangThaiMon !== 4) {
                    await fetch(`${URL_SERVER_API}/v1/thungan/capnhatchitetmon`, {
                        method: 'PUT',
                        headers: HEADER_TOKEN_API(token),
                        body: JSON.stringify({
                            maChiTiet: order.maChiTiet,
                            trangThai: 3
                        })
                    });
                }
            });

            sendReload()
        }
    });


    useEffect(() => {
        const layDuLieuBan = async () => {
            try {
                const result = await fetch(`${URL_SERVER_API}/v1/thungan/layordercuaban/${ban.maBan}`, { method: 'GET' });
                const resultJson = await result.json();

                if (resultJson.status === "successfully") {
                    const orderList = resultJson.value.listOrder;

                    if (orderList.length > 0) {
                        const orderTemp = new Map();

                        orderList.forEach(order => {
                            const existingOrder = orderTemp.get(order.maSanPham);
                            if (existingOrder) {
                                existingOrder.soLuong += order.soLuong;
                            } else {
                                orderTemp.set(order.maSanPham, { ...order });
                            }
                        });

                        // Convert Map thành mảng để sử dụng sau đó
                        const orderArray = Array.from(orderTemp.values());
                        setValueOrder(orderArray);
                    } else {
                        setValueOrder([]);
                    }
                }
            } catch (error) {
                console.error("Lỗi khi lấy dữ liệu:", error);
            }
        };

        if (ban && ban.maBan) {
            layDuLieuBan();
        }
    }, [ban, onReloadOrder]);

    useEffect(() => {
        if (inCheBien) {
            handlePrintCheBien();
            setInCheBien(false);
        }
    }, [valueCheBien, inCheBien]);

    const handleInCheBien = async () => {
        if(!ban || !ban.trangThai){
            showToast("error", "Hãy chọn một bàn!");
            return;
        }
        if (ban.trangThai === 4) {
            showToast("error", "bàn đã in hóa đơn không thể in chế biến!");
            return;
        }

        await setValueCheBien({
            maBan: ban.tenKhuVuc,
            ban: ban.tenBan,
            thoiGianIn: new Date().toLocaleString(),
            sanPham: valueOrder
        });
        await setInCheBien(true);

        // await handlePrintCheBien();
    };


    useEffect(() => {
        async function getValue() {
            const result = await fetch(`${URL_SERVER_API}/v1/tk/layThongTin`, {
                method: "GET",
                headers: HEADER_TOKEN_API(token)
            })
            const resiltJson = await result.json()
            if (resiltJson.status === "successfully") {
                setInforUser(resiltJson.value.ttTaiKhoan[0])
            }
        }
        getValue()
    }, [reload, ban])

    useEffect(() => {
        async function getDuLieu() {
            try {
                const response = await fetch(`${URL_SERVER_API}/v1/sys/docsysInfor`, { method: 'GET' });
                if (!response.ok) throw new Error('Network response was not ok');
                const resultJson = await response.json();


                const result = await fetch(`${URL_SERVER_API}/v1/tk/layThongTinTK/${resultJson.nguoiCapNhatCuoi}`, { method: 'GET' });
                const thongTinUser = await result.json()

                if (thongTinUser.status === "successfully") {
                    const nguoiUpdate = thongTinUser.value.ttTaiKhoan[0].hoVaTen + " - " + thongTinUser.value.ttTaiKhoan[0].tenDangNhap
                    resultJson.nguoiCapNhatCuoi = nguoiUpdate
                    resultJson.thoiGianCapNhatCuoi = formatDate(resultJson.thoiGianCapNhatCuoi)
                }
                setCuaHang(resultJson);
            } catch (error) {
                console.error('Error fetching store information:', error);
            }

        }
        getDuLieu();
    }, [reload, ban]);

    useEffect(() => {
        async function getDuLieu() {
            try {

                const response = await fetch(`${URL_SERVER_API}/v1/sys/docsetupbill`, { method: 'GET' });
                if (!response.ok) throw new Error('Network response was not ok');
                const resultJson = await response.json();
                setConfigBill(resultJson);
            } catch (error) {
                console.error('Error fetching bill configuration:', error);
            }
        }

        getDuLieu();
    }, [reload, ban]); // Đảm bảo rằng useEffect sẽ chạy lại khi reload thay đổi

    const handleInHoaDon = async () => {
        if(!ban || !ban.trangThai){
            showToast("error", "Hãy chọn một bàn!");
            return;
        }

        let sdt = "000"
        let tenKhach = "Khách vãng lai"
        if (khachSelect && khachSelect.label && khachSelect.label.length > 0) {
            const kh = khachSelect.label
            const khArr = await kh.split('-')
            sdt = khArr[1]
            tenKhach = khArr[0]
        }

        let dlCK = {}

        if (configBill.showQRCode) {
            // await getPaymentTypes(resultJson.selectedAccount);
            const thongTinQr = await fetch(`${URL_SERVER_API}/v1/ktt/thongtin/${configBill.selectedAccount}`, { method: 'GET' });

            if (thongTinQr.ok) {
                const thongTinQrJson = await thongTinQr.json()
                dlCK = thongTinQrJson[0]
            }
        }


        // console.log(valueOrder)
        await setValueBill({
            thoiGianIn: new Date().toLocaleString(),
            maHoaDon: maHoaDonSort,
            tenNhanVien: (inforUser.maTaiKhoan.slice(0, 2) + ".." + inforUser.maTaiKhoan.slice(-3)) + "-" + inforUser.hoVaTen,
            sdtKhach: sdt,
            tenKhach: tenKhach,
            sanPham: valueOrder,
            tongHoaDon: totalBill.tongHoaDon,
            chietKhau: totalBill.chietKhau,
            tongHoaDonCK: totalBill.tongSauChietKhau,
            tienKhachDua: totalBill.tienKhachDua,
            tienTraKhach: totalBill.tienThuaTraKhach,
            ban: {
                tenBan: ban.tenBan,
                tenKhuVuc: ban.tenKhuVuc
            },
            dlChuyenKhoan: {
                nganHang: dlCK?.nganHang || "",
                soTaiKhoan: dlCK?.soTaiKhoan || "",
                chuTaiKhoan: dlCK?.chuTaiKhoan || ""
            }
        });
        // await setInCheBien(true);
        await setTimeout(() => {

        }, 500);

        // await handlePrintHoaDon();
    }

    useEffect(() => {
        if (valueBill.maHoaDon !== "*****") {
            handlePrintHoaDon();
        }
    }, [valueBill]);

    const handlePrintHoaDon = useReactToPrint({

        contentRef: componentRefBill,
        documentTitle: 'Bill',
        onAfterPrint: async () => {

            // cập nhật trạng thái hóa đơn
            await fetch(`${URL_SERVER_API}/v1/thungan/capnhattrangthai`, {
                method: 'PUT',
                headers: HEADER_TOKEN_API(token),
                body: JSON.stringify({
                    maBan: ban.maBan,
                    trangThai: 4
                })
            });


            // cập nhật trạng thái món hiện tại
            valueOrder.forEach(async order => {
                if (order.trangThaiMon !== 4) {
                    await fetch(`${URL_SERVER_API}/v1/thungan/capnhatchitetmon`, {
                        method: 'PUT',
                        headers: HEADER_TOKEN_API(token),
                        body: JSON.stringify({
                            maChiTiet: order.maChiTiet,
                            trangThai: 4
                        })
                    });
                }
            });

            sendReload()
        }
    });



    const handleSaveHoaDon = async () => {
        if (ban.trangThai !== 4) {
            showToast("warning", "Hãy in hóa đơn!");
            return;
        }

        let maKhachHang = null
        let maKieuThanhToan = null

        if (khachSelect) {
            if (khachSelect.maKhach && khachSelect.maKhach !== "0000") {
                maKhachHang = khachSelect.maKhach
            }
            else {
                maKhachHang = null
            }
        }
        else {
            maKhachHang = null
        }

        if (selectPay) {
            if (selectPay.maKieuTT) {
                maKieuThanhToan = selectPay.maKieuTT
            }
            else {
                maKieuThanhToan = null
            }
        }
        else {
            maKieuThanhToan = null
        }

        const hoaDon = {
            maTraCuu: maHoaDonSort,
            maKhach: maKhachHang,
            maBan: ban.maBan,
            maKieuTT: maKieuThanhToan,
            tongHoaDon: totalBill.tongHoaDon,
            chietKhauHoaDon: totalBill.chietKhau,
        }

        const result = await fetch(`${URL_SERVER_API}/v1/thungan/luuHoaDon`, {
            method: 'POST',
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                ttHoaDon: hoaDon,
                chiTietHoaDon: valueOrder
            })
        });

        if (result.ok) {
            showToast("success", "Đã lưu thành công!");
            sendReload()
        }
        else {
            showToast("error", "Hóa đơn chưa được lưu thành công!");
        }

    }




    return (
        <div>
            <div className='mt-3'>
                <div className="d-flex d-lg-block flex-wrap flex-md-nowrap justify-content-between">
                    <button className="ui green button mini mb-2">
                        <i className="fa-solid fa-file me-2"></i>
                        Kết ca
                    </button>
                    <button className="ui yellow button mini mb-2" id="btn-print-handle-bill" onClick={handleInCheBien}>
                        <i className="fa-solid fa-hat-chef me-2"></i>
                        In chế biến
                    </button>
                    <button className="ui blue button mini mb-2" id="btn-print-bill" onClick={handleInHoaDon}>
                        <i className="fa-solid fa-print me-2"></i>
                        In hóa đơn
                    </button>
                    <button className="ui red button mini mb-2" id="btn-save-bill" onClick={handleSaveHoaDon}>
                        <i className="fa-solid fa-floppy-disk me-2"></i>
                        Lưu hóa đơn
                    </button>
                </div>
            </div>

            {/* Render BillCheBien và dùng ref để in */}
            <div className='d-none'>
                <BillCheBien ref={componentRef} valueBill={valueCheBien} />
                <Bill ref={componentRefBill} configBill={configBill} configInfo={cuahang} valueBill={valueBill} />

            </div>
        </div>
    );
}

export default ButtonHandleBill;
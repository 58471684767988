import React, { useEffect, useState } from 'react';
import TableElm from '../components/Table/TableElm';
import { URL_SERVER_API } from '../appConfig';
import ModalKhachHang from '../components/Modal/ModalKhachHang';
import ModalSanPham from '../components/Modal/ModalSanPham';
import useFormatDate from '../hooks/useFormatDate';

const QuanLySp = () => {

    const [reload, setReload] = useState(false)
    const [showModal, setShowModal] = useState('')
    const [valueSelect, setValueSelect] = useState(null)

    const [valueTable, setValueTable] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [totalItems, setTotalItems] = useState(0);

    const optPage = [
        {
            'type': 'update',
            'title': 'Updates'
        },
        {
            'type': 'delete',
            'title': 'Delete'
        },
    ];

    const TableGroup = 'tenNhomSanPham'

    const TableConfig = [
        {
            title: 'Mã sản phẩm',
            name: 'maSanPham',
            hidden: true
        },
        {
            title: 'Mã nhóm sản phẩm',
            name: 'maNhomSanPham',
            hidden: true
        },
        {
            title: 'Ảnh',
            name: 'anhSanPham',
            hidden: false
        },
        {
            title: 'Tên sản phẩm',
            name: 'tenSanPham',
            hidden: false
        },
        {
            title: 'Giá bán',
            name: 'donGia',
            hidden: false
        },
        {
            title: 'Đơn vị tính',
            name: 'donViTinh',
            hidden: false
        },
        {
            title: 'Ghi chú',
            name: 'ghiChu',
            hidden: false
        },
        {
            title: 'Ngày tạo',
            name: 'ngayTao',
            hidden: false
        },
    ];

    const offset = Math.max((currentPage - 1) * limit, 0);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);

    const formatDate = useFormatDate()
    useEffect(() => {
        async function getValue() {
            const result = await fetch(`${URL_SERVER_API}/v1/sp/filter?limit=${limit}&offset=${offset}&search=${debouncedSearchValue}`, { method: 'GET' });
            const resultJson = await result.json();

            let dataFormat = []
            if (resultJson.status === 'successfully') {
               
                for (const item of resultJson.value.data) {
                    item.ngayTao = formatDate(item.ngayTao)
                    dataFormat.push(item)
                }
                setTotalItems(resultJson.value.recordsTotal);
                setValueTable(dataFormat);
            }
        }
        getValue();
    }, [limit, offset, debouncedSearchValue, reload]);
    


    // 

    const handleClickBtn = (v, e) => {
        setValueSelect(v)
        setShowModal(e)
    }
    return (

        <>
            <div>
                <button className='ui button m-1 violet' onClick={() => setShowModal('create')}>Thêm sản phẩm mới</button>
                <div className='pt-2'>
                    <TableElm
                        optionButton={optPage}
                        TableConfig={TableConfig}
                        valueTable={valueTable}
                        totalItems={totalItems}
                        TableGroup={TableGroup}
                        onHandleClickBtn={(v, e) => { handleClickBtn(v, e) }}
                        onHandleSelectLimit={(e) => { setLimit(e); setCurrentPage(1); }}
                        onHandleChangeTxt={(e) => { setSearchValue(e); }}
                        onHandlePagination={(page) => { setCurrentPage(page); }}
                    />
                </div>
            </div>
            {
                showModal && (
                    <ModalSanPham
                        inforSanPham={valueSelect}
                        typeModal={showModal}
                        onClose={() => setShowModal('')}
                        onReload={() => setReload(!reload)}
                    />)

            }
        </>

    );
};

export default QuanLySp;

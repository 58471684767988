import React, { useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalUpdateInfo = ({ onClose = () => { }, inforUser = {}, onReload = () => { } }) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenDangNhap: '',
        hoVaTen: '',
        matKhau: ''
    });
    const [showPass, setShowPass] = useState(false);
    const [inforUserUpdate, setInforUserUpdate] = useState({
        tenDangNhap: inforUser.tenDangNhap || '',
        hoVaTen: inforUser.hoVaTen || '',
        matKhau: ''
    });

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforUserUpdate({ ...inforUserUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforUserUpdate.tenDangNhap) {
            errors.tenDangNhap = 'Vui lòng nhập tên đăng nhập';
            valid = false;
        }

        if (!inforUserUpdate.hoVaTen) {
            errors.hoVaTen = 'Vui lòng nhập họ và tên';
            valid = false;
        }

        if (!inforUserUpdate.matKhau) {
            errors.matKhau = 'Vui lòng nhập mật khẩu';
            valid = false;
        } else if (inforUserUpdate.matKhau.length < 8) {
            errors.matKhau = 'Mật khẩu phải có ít nhất 8 ký tự';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const saveUpdate = async () => {
        if (!validateInputs()) {
            return;
        }

        const result = await fetch(`${URL_SERVER_API}/v1/tk/update`, {
            method: "PUT",
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenDangNhap: inforUserUpdate.tenDangNhap,
                hoTen: inforUserUpdate.hoVaTen,
                matKhau: inforUserUpdate.matKhau
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "cntc") {
                showToast('success', 'Cập nhật thành công!');
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    tenDangNhap: resultJson.status === "tdndtt" ? "Tên đăng nhập đã tồn tại" : prevErr.tenDangNhap,
                    matKhau: resultJson.status === "smk" ? "Sai mật khẩu" : prevErr.matKhau
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinTK" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Cập nhật thông tin</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="mb-3">
                                <label htmlFor="tenDangNhap" className="form-label">Tên đăng nhập</label>
                                <input type="text" className={`form-control ${errStyle.tenDangNhap ? 'is-invalid' : ''}`} id="tenDangNhap"
                                    placeholder="Nhập tên đăng nhập của bạn"
                                    onChange={handleChange}
                                    value={inforUserUpdate.tenDangNhap}
                                />
                                <div className="invalid-feedback">{errStyle.tenDangNhap}</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="hoVaTen" className="form-label">Họ và tên</label>
                                <input type="text" className={`form-control ${errStyle.hoVaTen ? 'is-invalid' : ''}`} id="hoVaTen"
                                    placeholder="Nhập họ và tên của bạn"
                                    onChange={handleChange}
                                    value={inforUserUpdate.hoVaTen}
                                />
                                <div className="invalid-feedback">{errStyle.hoVaTen}</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="matKhau" className="form-label">Xác nhận mật khẩu cập nhật</label>
                                <div className="input-group">
                                    <input type={showPass ? "text" : "password"} className={`form-control ${errStyle.matKhau ? 'is-invalid' : ''}`} id="matKhau"
                                        placeholder="Nhập mật khẩu"
                                        minLength="8"
                                        onChange={handleChange}
                                    />
                                    <span className="input-group-text cursor-pointer" id="matKhau-show">
                                        <i id="matKhau-show-icon" onClick={() => setShowPass(!showPass)} className={`fa-solid ${showPass ? 'fa fa-eye' : 'fa-eye-slash'} `}></i>
                                    </span>
                                    <div className="invalid-feedback">{errStyle.matKhau}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        <button type="button" className="btn btn-primary" onClick={saveUpdate}>Lưu thay đổi</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalUpdateInfo;

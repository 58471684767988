import React, { useState, useEffect } from 'react';
import { HEADER_TOKEN_API, URL_SERVER, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalSanPham = ({
    onClose = () => { },
    onReload = () => { },
    inforSanPham = {}, // Dữ liệu thông tin sản phẩm
    typeModal = "create" // create, update, delete
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenSanPham: '',
        maNhomSanPham: '',
        donGia: '',
        donViTinh: '',
        anhSanPham: ''
    });
    const [nhomSPList, setNhomSPList] = useState([]); // Danh sách nhóm sản phẩm từ API
    const [inforSanPhamUpdate, setInforSanPhamUpdate] = useState(
        typeModal !== "create"
            ? {
                maSanPham: inforSanPham.maSanPham || '',
                tenSanPham: inforSanPham.tenSanPham || '',
                maNhomSanPham: inforSanPham.maNhomSanPham || '',
                donGia: inforSanPham.donGia || '',
                donViTinh: inforSanPham.donViTinh || '',
                anhSanPham: inforSanPham.anhSanPham || '',
                ghiChu: inforSanPham.ghiChu || ''
            }
            : {
                tenSanPham: '',
                maNhomSanPham: '',
                donGia: '',
                donViTinh: '',
                anhSanPham: '',
                ghiChu: ''
            }
    );
    const [encodedImage, setEncodedImage] = useState('');
    const [previewImage, setPreviewImage] = useState(''); // Dùng để xem trước ảnh

    useEffect(() => {
        // Gọi API để lấy danh sách nhóm sản phẩm
        const fetchNhomSPList = async () => {
            try {
                const result = await fetch(`${URL_SERVER_API}/v1/nhomsp/danhsach`, {
                    method: "GET",
                    headers: HEADER_TOKEN_API(token),
                });
                const resultJson = await result.json();
                if (resultJson.status === "successfully") {
                    setNhomSPList(resultJson.value.result);
                }
            } catch (error) {
                showToast('error', 'Lấy danh sách nhóm sản phẩm thất bại');
            }
        };

        fetchNhomSPList();
    }, []);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforSanPhamUpdate({ ...inforSanPhamUpdate, [id]: value });
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1]; // Lấy phần base64
                setEncodedImage(base64String);
                setPreviewImage(reader.result); // Hiển thị ảnh để xem trước
            };
            reader.readAsDataURL(file);
        }
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforSanPhamUpdate.tenSanPham) {
            errors.tenSanPham = 'Vui lòng nhập tên sản phẩm';
            valid = false;
        }
        if (!inforSanPhamUpdate.maNhomSanPham) {
            errors.maNhomSanPham = 'Vui lòng chọn nhóm sản phẩm';
            valid = false;
        }
        if (!inforSanPhamUpdate.donGia) {
            errors.donGia = 'Vui lòng nhập đơn giá';
            valid = false;
        }
        if (!inforSanPhamUpdate.donViTinh) {
            errors.donViTinh = 'Vui lòng nhập đơn vị tính';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const endpoint = typeModal === "create"

            ? `${URL_SERVER_API}/v1/sp/create`
            : `${URL_SERVER_API}/v1/sp/update/${inforSanPhamUpdate.maSanPham}`;
        const method = typeModal === "create" ? "POST" : "PUT";

        const bodyData = {
            tenSanPham: inforSanPhamUpdate.tenSanPham,
            maNhomSanPham: inforSanPhamUpdate.maNhomSanPham,
            donGia: inforSanPhamUpdate.donGia,
            donViTinh: inforSanPhamUpdate.donViTinh,
            ghiChu: inforSanPhamUpdate.ghiChu
        };
        console.log("🚀 ~ handleSave ~ endpoint:", endpoint)
        console.log("🚀 ~ handleSave ~ bodyData:", bodyData)



        // Thêm anhSanPham nếu có ảnh
        if (encodedImage) {
            bodyData.anhSanPham = encodedImage;
        }
        else {
            bodyData.anhSanPham = ''
        }

        try {
            const result = await fetch(endpoint, {
                method: method,
                headers: HEADER_TOKEN_API(token),
                body: JSON.stringify(bodyData)
            });
            const resultJson = await result.json();

            if (resultJson.status === "successfully") {
                showToast('success', `${typeModal === "create" ? 'Thêm' : 'Cập nhật'} sản phẩm thành công!`);
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    tenSanPham: resultJson.status === "TSPNDTT" ? "Tên sản phẩm đã tồn tại" : prevErr.tenSanPham
                }));
            }
        } catch (error) {
            showToast('error', 'Đã có lỗi xảy ra');
        }
    };

    const handleDelete = async () => {
        try {
            const result = await fetch(`${URL_SERVER_API}/v1/sp/delete/${inforSanPham.maSanPham}`, {
                method: 'DELETE',
                headers: HEADER_TOKEN_API(token),
            });
            const resultJson = await result.json();

            if (resultJson.status === 'successfully') {
                showToast('success', 'Xóa sản phẩm thành công!');
                onClose();
                onReload();  // Reload lại dữ liệu sau khi xóa
            } else {
                showToast('error', 'Xóa sản phẩm thất bại');
            }
        } catch (error) {
            showToast('error', 'Đã có lỗi xảy ra khi xóa sản phẩm');
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">
                            {typeModal === "create" ? "Thêm sản phẩm mới" : typeModal === "update" ? "Cập nhật thông tin sản phẩm" : "Xóa sản phẩm"}
                        </h1>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {typeModal !== "delete" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="tenSanPham" className="form-label">Tên sản phẩm</label>
                                    <input type="text" className={`form-control ${errStyle.tenSanPham ? 'is-invalid' : ''}`} id="tenSanPham"
                                        placeholder="Nhập tên sản phẩm" onChange={handleChange} value={inforSanPhamUpdate.tenSanPham} />
                                    <div className="invalid-feedback">{errStyle.tenSanPham}</div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="maNhomSanPham" className="form-label">Nhóm sản phẩm</label>
                                    <select id="maNhomSanPham" className={`form-select ${errStyle.maNhomSanPham ? 'is-invalid' : ''}`}
                                        onChange={handleChange} value={inforSanPhamUpdate.maNhomSanPham}>
                                        <option value="">Chọn nhóm sản phẩm</option>
                                        {nhomSPList.map(nsp => (
                                            <option key={nsp.maNhomSanPham} value={nsp.maNhomSanPham}>{nsp.tenNhomSanPham}</option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">{errStyle.maNhomSanPham}</div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="donGia" className="form-label">Đơn giá</label>
                                    <input type="number" className={`form-control ${errStyle.donGia ? 'is-invalid' : ''}`} id="donGia"
                                        placeholder="Nhập đơn giá" onChange={handleChange} value={inforSanPhamUpdate.donGia} />
                                    <div className="invalid-feedback">{errStyle.donGia}</div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="donViTinh" className="form-label">Đơn vị tính</label>
                                    <input type="text" className={`form-control ${errStyle.donViTinh ? 'is-invalid' : ''}`} id="donViTinh"
                                        placeholder="Nhập đơn vị tính" onChange={handleChange} value={inforSanPhamUpdate.donViTinh} />
                                    <div className="invalid-feedback">{errStyle.donViTinh}</div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="anhSanPham" className="form-label">Ảnh sản phẩm</label>
                                    <input type="file" className={`form-control ${errStyle.anhSanPham ? 'is-invalid' : ''}`} id="anhSanPham"
                                        onChange={handleFileChange} />
                                    <div className="invalid-feedback">{errStyle.anhSanPham}</div>
                                </div>

                                {/* Hiển thị ảnh xem trước */}
                                {(previewImage || inforSanPham.anhSanPham) && (
                                    <div className="mb-3">
                                        <label className="form-label">Review Image:</label>
                                        <img
                                            src={previewImage ? previewImage : typeModal !== "create" && `${URL_SERVER}/uploads/images/${inforSanPham.anhSanPham}`}
                                            alt="Review"
                                            className="img-thumbnail"
                                            style={{ width: '200px', height: '200px' }}
                                        />
                                    </div>
                                )}


                                <div className="mb-3">
                                    <label htmlFor="ghiChu" className="form-label">Ghi chú</label>
                                    <textarea className="form-control" id="ghiChu" rows="3"
                                        placeholder="Ghi chú" onChange={handleChange} value={inforSanPhamUpdate.ghiChu}></textarea>
                                </div>
                            </>
                        )}

                        {typeModal === "delete" && (
                            <div>Bạn có chắc chắn muốn xóa sản phẩm <strong>{inforSanPham.tenSanPham}</strong> không?</div>
                        )}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Đóng</button>
                        {typeModal === "create" && <button type="button" className="btn btn-primary" onClick={handleSave}>Thêm mới</button>}
                        {typeModal === "update" && <button type="button" className="btn btn-primary" onClick={handleSave}>Cập nhật</button>}
                        {typeModal === "delete" && <button type="button" className="btn btn-danger" onClick={handleDelete}>Xóa</button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSanPham;

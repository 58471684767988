import React, { useState, useEffect } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../appConfig';
import useTokenAccount from '../hooks/useTokenAccount';
import useShowToast from '../hooks/useShowToast';

const BillConfigForm = ({ configBill = {}, onUpdateConfig = () => { } }) => {
    const [formConfig, setFormConfig] = useState(configBill || {});
    const token = useTokenAccount()
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [saving, setSaving] = useState(false); // Trạng thái khi đang lưu
    const showToast = useShowToast();
    // Lấy danh sách tài khoản thanh toán từ API
    useEffect(() => {
        async function fetchPaymentMethods() {
            try {
                const response = await fetch(`${URL_SERVER_API}/v1/ktt/danhsach`);
                const data = await response.json();
                const filteredMethods = data.filter((method) => method.soTaiKhoan);
                setPaymentMethods(filteredMethods);
            } catch (error) {
                console.error('Lỗi khi lấy danh sách kiểu thanh toán:', error);
            }
        }
        fetchPaymentMethods();
    }, []);

    // Cập nhật cấu hình khi người dùng thay đổi giá trị
    const handleChange = (key, value) => {
        if (!formConfig) return; // Đảm bảo formConfig không phải null
        const newConfig = { ...formConfig, [key]: value };
        setFormConfig(newConfig);
        onUpdateConfig(newConfig);
    };

    // Hàm lưu thiết đặt vào API
    const saveConfig = async () => {
        formConfig.showOptional1 === false && (formConfig.optionalContent1 = "")
        formConfig.showOptional2 === false && (formConfig.optionalContent2 = "")
        formConfig.showQRCode === false && (formConfig.selectedAccount = "")

        formConfig.optionalContent1 === "" && (formConfig.showOptional1 = false)
        formConfig.optionalContent2 === "" && (formConfig.showOptional2 = false)
        formConfig.selectedAccount === "" && (formConfig.showQRCode = false)

        setSaving(true); // Đặt trạng thái đang lưu
        try {
            const response = await fetch(`${URL_SERVER_API}/v1/sys/ghisetupbill`, {
                method: 'POST',
                headers: HEADER_TOKEN_API(token),
                body: JSON.stringify(formConfig),
            });
            if (!response.ok) {

                throw new Error('Lỗi khi lưu thiết đặt');
            }
            showToast('success', 'Cập nhật cấu hình thành công!');
        } catch (error) {
            console.error('Lỗi khi lưu thiết đặt:', error);
            showToast('error', 'Cập nhật cấu hình không thành công!');

        } finally {
            setSaving(false); // Đặt lại trạng thái sau khi lưu xong
        }
    };

    return (
        <div className="config-form container mt-4 p-4 border rounded shadow-sm bg-light">
            <h4 className="text-primary mb-4">Cấu hình hóa đơn</h4>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showLogo}
                    onChange={(e) => handleChange('showLogo', e.target.checked)}
                    id="showLogo"
                />
                <label className="form-check-label" htmlFor="showLogo">
                    Hiển thị logo
                </label>
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showTaxCode}
                    onChange={(e) => handleChange('showTaxCode', e.target.checked)}
                    id="showTaxCode"
                />
                <label className="form-check-label" htmlFor="showTaxCode">
                    Hiển thị mã số thuế
                </label>
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showCashierName}
                    onChange={(e) => handleChange('showCashierName', e.target.checked)}
                    id="showCashierName"
                />
                <label className="form-check-label" htmlFor="showCashierName">
                    Hiển thị tên nhân viên thu ngân
                </label>
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showCustomerInfo}
                    onChange={(e) => handleChange('showCustomerInfo', e.target.checked)}
                    id="showCustomerInfo"
                />
                <label className="form-check-label" htmlFor="showCustomerInfo">
                    Hiển thị thông tin khách hàng
                </label>
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showTienDu}
                    onChange={(e) => handleChange('showTienDu', e.target.checked)}
                    id="showTienDu"
                />
                <label className="form-check-label" htmlFor="showTienDu">
                    Hiển thị tiền nhận/ tiền trả lại
                </label>
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showOptional1}
                    onChange={(e) => handleChange('showOptional1', e.target.checked)}
                    id="showOptional1"
                />
                <label className="form-check-label" htmlFor="showOptional1">
                    Hiển thị nội dung tùy chọn 1
                </label>
                {formConfig.showOptional1 && (
                    <input
                        className="form-control mt-2"
                        type="text"
                        value={formConfig.optionalContent1}
                        onChange={(e) => handleChange('optionalContent1', e.target.value)}
                        placeholder="Nhập nội dung tùy chọn 1"
                    />
                )}
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showOptional2}
                    onChange={(e) => handleChange('showOptional2', e.target.checked)}
                    id="showOptional2"
                />
                <label className="form-check-label" htmlFor="showOptional2">
                    Hiển thị nội dung tùy chọn 2
                </label>
                {formConfig.showOptional2 && (
                    <input
                        className="form-control mt-2"
                        type="text"
                        value={formConfig.optionalContent2}
                        onChange={(e) => handleChange('optionalContent2', e.target.value)}
                        placeholder="Nhập nội dung tùy chọn 2"
                    />
                )}
            </div>

            <div className="form-group form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={formConfig.showQRCode}
                    onChange={(e) => handleChange('showQRCode', e.target.checked)}
                    id="showQRCode"
                />
                <label className="form-check-label" htmlFor="showQRCode">
                    Hiển thị mã QR
                </label>
                {formConfig.showQRCode && (
                    <select
                        className="form-select mt-2"
                        value={formConfig.selectedAccount}
                        onChange={(e) => handleChange('selectedAccount', e.target.value)}
                    >
                        <option value="">Chọn tài khoản</option>
                        {paymentMethods.map((method) => (
                            <option key={method.maKieuTT} value={method.maKieuTT}>
                                {method.chuTaiKhoan} - {method.soTaiKhoan} ({method.nganHang})
                            </option>
                        ))}
                    </select>
                )}
            </div>

            <button
                className="btn btn-primary mt-3"
                onClick={saveConfig}
                disabled={saving} // Vô hiệu hóa khi đang lưu
            >
                {saving ? 'Đang lưu...' : 'Lưu thiết đặt'}
            </button>
        </div>
    );
};

export default BillConfigForm;

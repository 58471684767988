import React, { forwardRef } from 'react';

// Hàm để cắt ngắn tên sản phẩm
const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...'; // Cắt và thêm dấu "..."
  }
  return str;
};

// Sử dụng forwardRef để truyền ref vào BillCheBien
const BillCheBien = forwardRef(({ valueBill }, ref) => {
  return (
    <div ref={ref}>
      <div className='p-2'>
        <strong >Bill Chế Biến</strong>
        <div><strong>Khu vực:</strong> {valueBill.maBan}</div>
        <div><strong>Tên Bàn:</strong> {valueBill.ban}</div>
        <div><strong>In lúc:</strong> {valueBill.thoiGianIn}</div>
        {valueBill.nhanVienOrder && (
          <div><strong>Nhân Viên Order:</strong> {valueBill.nhanVienOrder}</div>
        )}

        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid #000' }}>Tên SP</th>
              <th style={{ borderBottom: '1px solid #000' }}>SL</th>
              <th style={{ borderBottom: '1px solid #000' }}>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {valueBill.sanPham.map((sp, index) => (
              <tr key={index}>
                <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>
                  {truncateString(sp.tenSanPham, 20)} {/* Cắt ngắn tên sản phẩm ở đây */}
                </td>
                <td style={{ padding: '5px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                  {sp.soLuong}
                </td>
                <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>
                  {sp.ghiChuSP}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default BillCheBien;

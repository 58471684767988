import React, { useState, useEffect } from 'react';
import ListButtonAdmin from '../components/ListButtonAdmin';
import './header.css';
import { useIsLogin } from '../contexts/IsLoginContext';
import useTokenAccount from '../hooks/useTokenAccount';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../appConfig';
import useFormatDate from '../hooks/useFormatDate'
import ModalUpdateInfo from '../components/Modal/ModalUpdateInfo';
import ModalUpdatePass from '../components/Modal/ModalUpdatePass';
import Cookies from 'js-cookie';

const Header = () => {
    const [reload, setReload] = useState(false)
    const [showLogout, setShowLogout] = useState(false);
    const [showModal, setShowModal] = useState('')
    const [inforUser, setInforUser] = useState({})
    const { logout } = useIsLogin()
    const token = useTokenAccount()
    const formatDate = useFormatDate()


    useEffect(() => {

        async function getValue() {
            const result = await fetch(`${URL_SERVER_API}/v1/tk/layThongTin`, {
                method: "GET",
                headers: HEADER_TOKEN_API(token)
            })
            const resiltJson = await result.json()
            if (resiltJson.status === "successfully") {
                try {
                    const maTaiKhoanSort = resiltJson.value.ttTaiKhoan[0].maTaiKhoan
                    window.maTaiKhoanSort = maTaiKhoanSort.slice(-5)
                    Cookies.set('maTaiKhoanSort',maTaiKhoanSort.slice(-5))
                } catch (error) {

                }
                setInforUser(resiltJson.value.ttTaiKhoan[0])
            }
        }
        getValue()
    }, [reload])

    const handleLogout = () => {
        logout()
        setShowLogout(false);
    }

    const handleCloseOffcanvas = () => {
        setShowLogout(false);
    }

    useEffect(() => {
        let timer;
        if (showLogout) {
            timer = setTimeout(() => {
                setShowLogout(false);
            }, 3000);
        }

        return () => clearTimeout(timer);
    }, [showLogout]);

    useEffect(() => {
        const offcanvasElement = document.getElementById('offcanvasNavbar');
        const handleOffcanvasClose = () => {
            setShowLogout(false);
        };
        offcanvasElement.addEventListener('hidden.bs.offcanvas', handleOffcanvasClose);
        return () => {
            offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffcanvasClose);
        };
    }, []);

    return (
        <>
            <nav className="navbar bg-body-tertiary fixed-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#" id='title-header'>Màn hình thu ngân</a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                        aria-label="Toggle navigation"
                        onClick={handleCloseOffcanvas}
                    >
                        <span><i className="fa-solid fa-user" style={{ fontSize: '1.8rem' }}></i></span>
                    </button>

                    <div className="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Xin chào {inforUser.tenDangNhap}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleCloseOffcanvas}></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item mb-1 opacity-75">
                                    <span aria-current="page">Tên đăng nhập: <span id="tenDN">{inforUser.tenDangNhap}</span></span>
                                </li>
                                <li className="nav-item mb-1 opacity-75">
                                    <span aria-current="page">Họ và tên: <span id="hoVaTen">{inforUser.hoVaTen}</span></span>
                                </li>
                                <li className="nav-item mb-1 opacity-75">
                                    <span aria-current="page">Ngày tạo tài khoản: <span id="ngayTao">{formatDate(inforUser.ngayTao)}</span></span>
                                </li>

                                <li className="nav-item dropdown">
                                    <span className="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Quản lý tài khoản
                                    </span>
                                    <ul className="dropdown-menu">
                                        <li><span onClick={() => setShowModal("CapNhatInfo")} className="dropdown-item" id="btn_capn_nhat" data-bs-dismiss="offcanvas">Cập nhật thông tin</span></li>
                                        <li><span onClick={() => setShowModal("CapNhatPass")} className="dropdown-item" id="doi_mat_khau" data-bs-dismiss="offcanvas">Đổi mật khẩu</span></li>
                                    </ul>
                                </li>

                                <li className="nav-item mt-4">
                                    <a href="/danh-thu-cua-toi"><button className="btn btn-outline-info w-100" data-bs-dismiss="offcanvas" id="btn_danh_thu_ca_nhan">Danh thu của tôi</button></a>
                                </li>
                                <li className="nav-item mt-2">
                                    <a href="/hoat-dong-cua-toi"><button className="btn btn-outline-success w-100" data-bs-dismiss="offcanvas" id="btn_lich_su_active">Lịch sử hoạt động</button></a>
                                </li>

                                <div>
                                    <ListButtonAdmin />
                                </div>

                                <li className="nav-item mt-2">
                                    <button onClick={() => setShowLogout(true)} disabled={showLogout} className="btn btn-outline-danger w-100" id="btn_dang_xuat">Đăng xuất</button>
                                    {showLogout && (
                                        <div id="bubble_dang_xuat" className="w-100 bg-gradient d-flex justify-content-between ui pointing red basic label">
                                            <span><button className="btn btn-danger" id="btn_xac_nhan_dx" onClick={handleLogout}>Xác nhận đăng xuất</button></span>
                                            <span><button className="btn btn-outline-success" id="btn_huy_dx" onClick={() => setShowLogout(false)}>Hủy đăng xuất</button></span>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </nav>

            {
                showModal === "CapNhatInfo" && (
                    <ModalUpdateInfo
                        inforUser={inforUser}
                        onClose={() => setShowModal('')}
                        onReload={() => setReload(!reload)}
                    />)

            }

            {
                showModal === "CapNhatPass" && (
                    <ModalUpdatePass
                        inforUser={inforUser}
                        onClose={() => setShowModal('')}
                        onReload={() => setReload(!reload)}
                    />)

            }
        </>
    );
}

export default Header;

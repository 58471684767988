import React, { useEffect, useState } from 'react';

const ControlAnalysis = ({ onChangeControlAnalysis = () => { } }) => {
    const [selectTypeAnalysis, setSelectTypeAnalysis] = useState('thquy');
    const [arrayYear, setArrayYear] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState('quy1');
    const [yearSelect, setYearSelect] = useState(0)
    const [valueChonNgay, setValueChonNgay] = useState('')

    const [tuNgay, setTuNgay] = useState('')
    const [denNgay, setDenNgay] = useState('')

    useEffect(() => {
        const namHienTai = new Date().getFullYear();
        setYearSelect(namHienTai)
        const namNhoNhat = 2020;
        const arrNam = Array.from({ length: namHienTai - namNhoNhat + 1 }, (_, i) => namNhoNhat + i);
        setArrayYear(arrNam);
    }, []);

    function formatDate_quarter(date) {
        return date.toISOString().slice(0, 10) + " " + date.toTimeString().slice(0, 8);
    }

    function getQuarterDateRange(quarter, year) {
        let startDate, endDate;

        switch (quarter) {
            case "quy1":
                startDate = new Date(Date.UTC(year, 0, 1, 0, 0, 0));  // Ngày 1 tháng 1, 00:00:00 UTC
                endDate = new Date(Date.UTC(year, 2, 31, 23, 59, 59));  // Ngày 31 tháng 3, 23:59:59 UTC
                break;
            case "quy2":
                startDate = new Date(Date.UTC(year, 3, 1, 0, 0, 0));  // Ngày 1 tháng 4, 00:00:00 UTC
                endDate = new Date(Date.UTC(year, 5, 30, 23, 59, 59));  // Ngày 30 tháng 6, 23:59:59 UTC
                break;
            case "quy3":
                startDate = new Date(Date.UTC(year, 6, 1, 0, 0, 0));  // Ngày 1 tháng 7, 00:00:00 UTC
                endDate = new Date(Date.UTC(year, 8, 30, 23, 59, 59));  // Ngày 30 tháng 9, 23:59:59 UTC
                break;
            case "quy4":
                startDate = new Date(Date.UTC(year, 9, 1, 0, 0, 0));  // Ngày 1 tháng 10, 00:00:00 UTC
                endDate = new Date(Date.UTC(year, 11, 31, 23, 59, 59));  // Ngày 31 tháng 12, 23:59:59 UTC
                break;
            case "quy5":
                startDate = new Date(Date.UTC(year, 0, 1, 0, 0, 0));  // Ngày 1 tháng 1, 00:00:00 UTC
                endDate = new Date(Date.UTC(year, 11, 31, 23, 59, 59));  // Ngày 31 tháng 12, 23:59:59 UTC
                break;
            default:
                return "Quý không hợp lệ!";
        }

        return {
            startDate: formatDate_quarter(startDate),
            endDate: formatDate_quarter(endDate)
        };
    }


    const handleChange = (value) => {
        setSelectedQuarter(value)
        onChangeControlAnalysis(getQuarterDateRange(value, yearSelect), 'quy')
    }

    const handleSelectYear = (value) => {
        setYearSelect(value)
        onChangeControlAnalysis(getQuarterDateRange(selectedQuarter, value), 'quy')
    }

    const handleChangeChonNgay = (value) => {
        setValueChonNgay(value)
        onChangeControlAnalysis(value, 'ngay')

    }

    const handleChangeKhoangNgay = (value, type) => {
        const formattedDateTime = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // tháng bắt đầu từ 0 nên cần cộng 1
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        if (type === "tuNgay") {
            setTuNgay(value);
            if (denNgay) {
                onChangeControlAnalysis(
                    { tuNgay: formattedDateTime(value), denNgay: formattedDateTime(denNgay) },
                    "khoangNgay"
                );
            }
        }

        if (type === "denNgay") {
            setDenNgay(value);
            if (tuNgay) {
                onChangeControlAnalysis(
                    { tuNgay: formattedDateTime(tuNgay), denNgay: formattedDateTime(value) },
                    "khoangNgay"
                );
            }
        }
    };


    return (
        <div className='p-2'>
            <div>
                <span>
                    <input
                        type="radio"
                        id='thquy'
                        name='optAnaly'
                        onChange={() => setSelectTypeAnalysis('thquy')}
                        checked={selectTypeAnalysis === "thquy"}
                    />
                    <label htmlFor="thquy" className='ms-2'>Lọc theo quý</label>
                </span>
                <span className='ms-3'>
                    <input
                        type="radio"
                        id='ngay'
                        name='optAnaly'
                        onChange={() => setSelectTypeAnalysis('ngay')}
                        checked={selectTypeAnalysis === "ngay"}
                    />
                    <label htmlFor="ngay" className='ms-2'>Lọc theo ngày</label>
                </span>
                <span className='ms-3'>
                    <input
                        type="radio"
                        id='khoangngay'
                        name='optAnaly'
                        onChange={() => setSelectTypeAnalysis('khoangngay')}
                        checked={selectTypeAnalysis === "khoangngay"}
                    />
                    <label htmlFor="khoangngay" className='ms-2'>Lọc khoảng ngày</label>
                </span>
            </div>

            <div className='mt-2 mb-2'>
                {selectTypeAnalysis === "thquy" && (
                    <div className='d-flex flex-wrap align-items-center mt-2'>
                        {['quy1', 'quy2', 'quy3', 'quy4', 'quy5'].map((value, index) => (
                            <span key={value} className='ms-2'>
                                <input
                                    type="radio"
                                    id={value}
                                    name='quy'
                                    onChange={() => handleChange(value)}
                                    checked={selectedQuarter === value}
                                />
                                <label className='ms-1' htmlFor={value}>
                                    {value === 'quy5' ? 'Cả năm' : `Quý ${index + 1}`}
                                </label>
                            </span>
                        ))}
                        <span className='ms-3'>
                            <label className='me-1' htmlFor="cuaNam">Năm</label>
                            <select id='cuaNam' value={yearSelect} onChange={(e) => handleSelectYear(e.target.value)}>
                                {arrayYear.map(item => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                        </span>

                    </div>
                )}

                {selectTypeAnalysis === "ngay" && (
                    <div className='d-flex mt-2 align-items-center'>
                        <label htmlFor="chonNgay">Chọn thời gian</label>
                        <input type="date" value={valueChonNgay} onChange={(e) => handleChangeChonNgay(e.target.value)} id='chonNgay' className='form-control ms-3' style={{ width: "250px" }} />
                    </div>
                )}

                {selectTypeAnalysis === "khoangngay" && (
                    <div className='d-flex flex-column mt-2'>
                        <div className='d-flex align-items-center'>
                            <label htmlFor="fromDate" style={{ width: '30px' }}>Từ</label>
                            <input value={tuNgay} onChange={(e) => handleChangeKhoangNgay(e.target.value, 'tuNgay')} type="datetime-local" id='fromDate' className='form-control ms-3' style={{ width: "250px" }} />
                        </div>
                        <div className='d-flex align-items-center mt-2'>
                            <label htmlFor="toDate" style={{ width: '30px' }}>Đến</label>
                            <input value={denNgay} onChange={(e) => handleChangeKhoangNgay(e.target.value, 'denNgay')} type="datetime-local" id='toDate' className='form-control ms-3' style={{ width: "250px" }} />
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}

export default ControlAnalysis;

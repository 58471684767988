import React from 'react'

const ModalContainer = ({ children }) => {
    return (
        <div
            className='position-fixed top-0 start-0 h-100 w-100 z-2 d-flex justify-content-center'
            style={{ paddingTop: '60px', backgroundColor: 'rgba(var(--bs-secondary-bg-rgb), 0.5)' }}>
            <div className='mt-3'>{children}</div>
        </div>
    )
}


export default ModalContainer
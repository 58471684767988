import React, { useEffect, useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';

const ChuyenBan = ({ onClose = () => { }, onReload = () => { }, banCanChuyen }) => {
  const [valueBan, setValueBan] = useState([]);
  const [selectedArea, setSelectedArea] = useState(''); // To handle the selected area
  const [banSelect, setBanSelect] = useState('');
  const [showChuyenBan, setShowChuyenBan] = useState(false);

  const token = useTokenAccount()

  useEffect(() => {
    async function getValue() {
      try {
        const result = await fetch(`${URL_SERVER_API}/v1/ban/filter?limit=1000&offset=0`, {
          method: 'GET',
        });
        if (!result.ok) {
          alert('Đã có lỗi nghiêm trọng');
          return;
        }
        const resultJson = await result.json();
        const dsKvVsBan = resultJson.value.data;
        const groupKhuVuc = dsKvVsBan.reduce((acc, item) => {
          const key = item['tenKhuVuc'];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
          return acc;
        }, {});
        const groupedData = Object.entries(groupKhuVuc);

        setValueBan(groupedData);
        setSelectedArea(groupedData.length > 0 ? groupedData[0][0] : ''); // Set initial area
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Có lỗi khi lấy dữ liệu');
      }
    }

    getValue();
  }, []);

  // Function to extract the number from "Bàn" names
  const getBanNumber = (tenBan) => {
    const match = tenBan.match(/\d+/); // Extract digits from the string
    return match ? parseInt(match[0], 10) : 0;
  };

  // Handling the area change
  const handleAreaChange = (area) => {
    setSelectedArea(area);
  };

  const handleChuyenBan = async () => {

    await fetch(`${URL_SERVER_API}/v1/thungan/chuyenban`, {
      method: 'POST',
      headers: HEADER_TOKEN_API(token),
      body: JSON.stringify({
        maBanTu: banCanChuyen.maBan,
        maBanDen: banSelect,
        trangThai: banCanChuyen.trangThai
      })
    });

    onReload()
    onClose()
  }

  return (
    <div className="bg-body pb-5 " style={{ borderRadius: '5px' }}>
      <h4 className='text-center p-2'>Chuyển bàn</h4>
      <ul className="nav nav-tabs">
        {valueBan.map(([area], index) => (
          <li className="nav-item" key={index}> {/* Use the index directly as the key */}
            <a
              className={`nav-link ${selectedArea === area ? 'active' : ''}`}
              onClick={() => handleAreaChange(area)}
              href="#"
            >
              {area}
            </a>
          </li>
        ))}
      </ul>


      <div className="table-buttons mt-3">
        {valueBan
          .filter(([area]) => area === selectedArea)
          .flatMap(([area, tables]) =>
            tables
              .sort((a, b) => getBanNumber(a.tenBan) - getBanNumber(b.tenBan)) // Sorting by numerical value
              .map((ban) => {
                if (ban.maBan !== banCanChuyen.maBan && ban.trangThai === 1) {
                  return (
                    <button
                      key={ban.maBan}
                      className={`ui button mini m-2 ${banSelect === ban.maBan ? 'active' : ''}`}
                      onClick={() => setBanSelect(ban.maBan)}
                    >
                      {ban.tenBan}
                    </button>
                  )
                }

              })
          )}
      </div>

      <div className="mt-2 ms-2">
        <button className="ui button red tiny" onClick={() => onClose()}>Hủy</button>
        <button className="ui button orange tiny" onClick={() => handleChuyenBan()}>Xác nhận chuyển</button>
      </div>
    </div>
  );
};

export default ChuyenBan;

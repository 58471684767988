import React from 'react'
import QuanLySp from './QuanLySp'
import QuanLyNhomSP from './QuanLyNhomSP'

const QuanLySpNhomSP = () => {
    return (
        <div className='row p-2 bg-body-secondary'>
            <div className='col-md-5'>
                <QuanLyNhomSP />
            </div>
            <div className='col-md-7'>
                <QuanLySp />
            </div>
        </div>
    )
}

export default QuanLySpNhomSP
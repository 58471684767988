function taoMaHoaDon() {
    const now = new Date();

    // Hàm thêm 0 ở trước nếu số nhỏ hơn 10
    const padZero = (num) => (num < 10 ? '0' + num : num);

    // Lấy các giá trị ngày tháng và thời gian
    const ngay = padZero(now.getDate());
    const thang = padZero(now.getMonth() + 1); // Tháng bắt đầu từ 0 nên cần cộng thêm 1
    const nam = now.getFullYear();
    const gio = padZero(now.getHours());
    const phut = padZero(now.getMinutes());
    const giay = padZero(now.getSeconds());
    const milyS = padZero(now.getMilliseconds())

    // Tạo chuỗi theo định dạng yêu cầu
    return `hd-${ngay}${thang}${nam}${gio}${phut}${giay}${milyS}`;
}

export default taoMaHoaDon
// exports.URL_SERVER_API = "http://localhost:3001/api"
// exports.URL_SERVER = "http://localhost:3001"

// exports.URL_SERVER_API = "http://192.168.11.187:3001/api"
// exports.URL_SERVER = "http://192.168.11.187:3001"

exports.URL_SERVER_API = "https://lvhuu.id.vn/api"
exports.URL_SERVER = "https://lvhuu.id.vn/"

exports.HEADER_TOKEN_API = (token) => {
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
}
import React, { forwardRef} from 'react';

const Bill = forwardRef(({ configBill = {
    showLogo: false,
    showTaxCode: false,
    showCashierName: false,
    showCustomerInfo: false,
    showOptional1: false,
    optionalContent1: "",
    showOptional2: true,
    optionalContent2: "",
    showQRCode: false,
    selectedAccount: ""
}, configInfo = {
    logoBase64: "",
    tenCuahang: "",
    sdtCuuaHang: "",
    diaChiCuahang: "",
    maSoThue: ""
}, valueBill = {
    thoiGianIn: new Date().toLocaleString(),
    maHoaDon: "HD123456",
    tenNhanVien: "Nguyen Van A",
    sdtKhach: "0123456789",
    tenKhach: "Tran B",
    sanPham: [
        { tenSanPham: "Sản phẩm A", soLuong: 1, donGia: 50000 },
        { tenSanPham: "Sản phẩm B", soLuong: 2, donGia: 30000 }
    ],
    chietKhau: 10000,
    tongHoaDon: 130000,
    ban: {
        tenBan: "",
        tenKhuVuc: ""
    },
    dlChuyenKhoan: {
        nganHang: "",
        soTaiKhoan: "",
        chuTaiKhoan: ""
    }
},
    maHoaDonShort = "abc"
}, ref) => {

    

    return (
        <div ref={ref} className="bill border p-3 screen-print" style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
            <span className='position-absolute top-0 mt-1 start-0 ms-2 opacity-75'>{valueBill.maHoaDon}</span> 
            <span className='position-absolute top-0 mt-1 end-0 me-2 opacity-75'>Lần in <span>1</span></span>
            {/* Logo */}
            {configBill?.showLogo && (
                <div className="logo text-center">
                    <img src={configInfo.logoBase64 || ''} alt="Logo cửa hàng" style={{ maxWidth: '70px', height: 'auto' }} />
                </div>
            )}

            {/* Thông tin cửa hàng */}
            <div className="store-info text-center mt-2">
                <h5 className="mb-1" style={{ fontSize: '14px' }}>{configInfo.tenCuahang}</h5>
                <p className="mb-1">SĐT: {configInfo.sdtCuuaHang}</p>
                <p className="mb-1">Địa chỉ: {configInfo.diaChiCuahang}</p>
                {configBill?.showTaxCode && <p className="mb-1">Mã số thuế: {configInfo.maSoThue}</p>}
            </div>

            {/* Thời gian và mã hóa đơn */}
            <div className="text-left mt-2">
                <p className="mb-1">Thời gian in: <strong>{valueBill.thoiGianIn}</strong></p>
                {/* <p className="mb-1">Mã hóa đơn: <strong>{valueBill.maHoaDon}</strong></p> */}
                <p className="mb-1">VT: <strong>{valueBill.ban.tenBan} - {valueBill.ban.tenKhuVuc}</strong></p>
                {configBill?.showCashierName && <p className="mb-1">Nhân viên: <strong>{valueBill.tenNhanVien}</strong></p>}
                {configBill?.showCustomerInfo && (
                    <div>
                        <p className="mb-1">SĐT KH: <strong>{valueBill.sdtKhach}</strong></p>
                        <p className="mb-1">Tên KH: <strong>{valueBill.tenKhach}</strong></p>
                    </div>
                )}
            </div>

            {/* Tiêu đề hóa đơn */}
            <div className="text-center mt-3">
                <h6 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '5px', margin: '0' }}>HÓA ĐƠN</h6>
            </div>

            {/* Bảng sản phẩm */}
            <div className="product-list mt-2">
                <table className="w-100">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Sản phẩm</th>
                            <th style={{ textAlign: 'center' }}>SL</th>
                            <th style={{ textAlign: 'right' }}>Đơn giá</th>
                            <th style={{ textAlign: 'right' }}>Thành tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Đây là ví dụ, có thể thay đổi theo dữ liệu */}
                        {
                            valueBill.sanPham.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.tenSanPham}</td>
                                        <td style={{ textAlign: 'center' }}>{item.soLuong}</td>
                                        <td style={{ textAlign: 'right' }}>{(item.donGia).toLocaleString()} đ</td>
                                        <td style={{ textAlign: 'right' }}>{(parseInt(item.soLuong) * parseInt(item.donGia)).toLocaleString()} đ</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>

                    <tfoot>
                        <tr>
                            <td colSpan="4" style={{ textAlign: 'right' }}>
                                Tổng đơn: {(parseInt(valueBill?.tongHoaDon)).toLocaleString() || '0'} đ
                            </td>
                        </tr>

                        {valueBill?.chietKhau > 0 && (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'right' }}>
                                    Chiết khấu: {valueBill?.chietKhau || '0'} %
                                </td>
                            </tr>
                        )}

                        {valueBill?.chietKhau > 0 && (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'right' }}>
                                    Tổng sau chiết khấu: {valueBill?.tongHoaDonCK?.toLocaleString() || '0'} đ
                                </td>
                            </tr>
                        )}

                        {(configBill?.showTienDu && valueBill?.tienKhachDua > 0) && (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'right' }}>
                                    Tiền khách đưa: {parseInt(valueBill?.tienKhachDua)?.toLocaleString() || '0'} đ
                                </td>
                            </tr>
                        )}

                        {(configBill?.showTienDu && valueBill?.tienKhachDua > 0) && (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'right' }}>
                                    Tiền trả khách: {valueBill?.tienTraKhach || '0'} đ
                                </td>
                            </tr>
                        )}

                    </tfoot>
                </table>
            </div>

            <div className="thank-you mt-3">
                {configBill?.showOptional1 && <p className="mb-1 text-center">{configBill?.optionalContent1}</p>}
                {configBill?.showOptional2 && <p className="mb-1 text-center">{configBill?.optionalContent2}</p>}
            </div>

            {configBill?.showQRCode && (
                <div className="qr-code text-center mt-2">
                    <img src={`https://img.vietqr.io/image/${valueBill.dlChuyenKhoan.nganHang}-${valueBill.dlChuyenKhoan.soTaiKhoan}-compact.jpg?amount=${valueBill.tongHoaDon - (valueBill.tongHoaDon * (valueBill.chietKhau / 100))}&addInfo=Thanh%20Toan%20hoa%20don%20${configInfo.tenCuahang}&accountName=${valueBill.dlChuyenKhoan.chuTaiKhoan}`} 
                    alt="QR Code chuyển khoản" 
                    style={{ maxWidth: '150px', height: 'auto' }} />
                </div>
            )}
        </div>
    );
});
export default Bill;

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { URL_SERVER_API } from '../../appConfig';

const TuyChonKhachHang = ({ onHandleSelect = () => { } }) => {
    const [listKhach, setListKhach] = useState([]);
    const [selectedKhach, setSelectedKhach] = useState(null);

    useEffect(() => {
        async function getValue() {
            const result = await fetch(`${URL_SERVER_API}/v1/kh/dsKhachHang`, { method: 'GET' });
            const resultJson = await result.json();

            let dsKhach = [
                {
                    maKhach: "0000",
                    tenKhach: "Khách vãng lai",
                    sdt: "00000000",
                }
            ];

            if (result.ok) {
                for (const item of resultJson) {
                    dsKhach.push({
                        maKhach: item.maKhach,
                        tenKhach: item.tenKhach,
                        sdt: item.sdt,
                    });
                }
            }

            const formattedValues = dsKhach.map(khach => ({
                value: khach.sdt,
                label: `${khach.tenKhach}-${khach.sdt}`,
                maKhach: khach.maKhach
            }));

            setListKhach(formattedValues);

            // Set default selected customer (Khách vãng lai)
            setSelectedKhach(formattedValues[0]);
        }

        getValue();
    }, []);

    // Function to handle customer selection
    const handleSelect = (selectedOption) => {
        setSelectedKhach(selectedOption);
        onHandleSelect(selectedOption)
        console.log("Selected customer:", selectedOption);
    };

    return (
        <div className='mt-2' >
            <span className='ui label mb-2'>Thông tin khách hàng</span>
            <Select
                value={selectedKhach}
                placeholder="Chọn khách hàng"
                options={listKhach}
                onChange={handleSelect}
            />
            <button className='ui button mini teal mt-1 mb-1'>Thêm thông tin khách</button>
        </div>
    );
};

export default TuyChonKhachHang;

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ErrorPage from '../page/ErrorPage'
import QuanLyTTKhach from '../page/QuanLyTTKhach'
import QuanLySpNhomSP from '../page/QuanLySpNhomSP'
import QuanLyKhuVucBan from '../page/QuanLyKhuVucBan'
import QuanLyKieuTT from '../page/QuanLyKieuTT'
import QuanLyCauHinhSys from '../page/QuanLyCauHinhSys'
import MangHinhThuNgan from '../page/MangHinhThuNgan'
import MangHinhThuNganPlus from '../page/MangHinhThuNganPlus'
import QuanLyDanhThuCaNhan from '../page/QuanLyDanhThuCaNhan'
const Router = () => {
    return (
        <>
            <Switch>
                <Route exact path="/">
                    {/* <div>Hello</div> */}
                    <MangHinhThuNganPlus />
                </Route>
                <Route path="/man_hinh_thu_ngan">
                    <MangHinhThuNganPlus />
                </Route>
                <Route path="/ql_ban_hang">
                    <div>ql_ban_hang</div>
                </Route>
                <Route path="/ql_kv_ban">
                    <QuanLyKhuVucBan />
                </Route>
                <Route path="/ql_san_pham">
                    <QuanLySpNhomSP />
                </Route>
                <Route path="/ql_tt_kh">
                    <QuanLyTTKhach />
                </Route>
                <Route path="/danh-thu-cua-toi">
                    <QuanLyDanhThuCaNhan />
                </Route>

                <Route path="/ql_kieu_tt">
                    <QuanLyKieuTT />
                </Route>
                <Route path="/ql_ch_he_thong">
                    <QuanLyCauHinhSys />
                </Route>
                <Route exact path="*">
                    <ErrorPage />
                </Route>
            </Switch>
        </>
    )
}

export default Router
import React, { useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalNhomSP = ({
    onClose = () => { },
    onReload = () => { },
    inforNhomSP = {}, // Dữ liệu thông tin nhóm sản phẩm
    typeModal = "create" // create, update, delete
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenNhomSanPham: '',
        moTa: ''
    });
    const [inforNhomSPUpdate, setInforNhomSPUpdate] = useState(
        typeModal !== "create"
            ? {
                maNhomSanPham: inforNhomSP.maNhomSanPham || '',
                tenNhomSanPham: inforNhomSP.tenNhomSanPham || '',
                moTa: inforNhomSP.moTa || ''
              }
            : {
                maNhomSanPham: '',
                tenNhomSanPham: '',
                moTa: ''
              }
    );
    

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforNhomSPUpdate({ ...inforNhomSPUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforNhomSPUpdate.tenNhomSanPham) {
            errors.tenNhomSanPham = 'Vui lòng nhập tên nhóm sản phẩm';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const endpoint = typeModal === "create" ? `${URL_SERVER_API}/v1/nhomsp/create` : `${URL_SERVER_API}/v1/nhomsp/update/${inforNhomSPUpdate.maNhomSanPham}`;
        const method = typeModal === "create" ? "POST" : "PUT";

        const result = await fetch(endpoint, {
            method: method,
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenNhomSanPham: inforNhomSPUpdate.tenNhomSanPham,
                moTa: inforNhomSPUpdate.moTa
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', `${typeModal === "create" ? 'Thêm' : 'Cập nhật'} nhóm sản phẩm thành công!`);
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    tenNhomSanPham: resultJson.status === "TNSPNDTT" ? "Tên nhóm sản phẩm đã tồn tại" : prevErr.tenNhomSanPham
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    const handleDelete = async () => {
        const result = await fetch(`${URL_SERVER_API}/v1/nhomsp/delete/${inforNhomSPUpdate.maNhomSanPham}`, {
            method: "DELETE",
            headers: HEADER_TOKEN_API(token),
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', 'Xóa nhóm sản phẩm thành công!');
                onClose();
                onReload();
            } else {
                showToast('error', 'Xóa nhóm sản phẩm thất bại!');
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinNhomSP" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">
                            {typeModal === "create" ? "Thêm nhóm sản phẩm mới" : typeModal === "update" ? "Cập nhật thông tin nhóm sản phẩm" : "Xóa nhóm sản phẩm"}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {typeModal !== "delete" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="tenNhomSanPham" className="form-label">Tên nhóm sản phẩm</label>
                                    <input type="text" className={`form-control ${errStyle.tenNhomSanPham ? 'is-invalid' : ''}`} id="tenNhomSanPham"
                                        placeholder="Nhập tên nhóm sản phẩm"
                                        onChange={handleChange}
                                        value={inforNhomSPUpdate.tenNhomSanPham}
                                    />
                                    <div className="invalid-feedback">{errStyle.tenNhomSanPham}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="moTa" className="form-label">Mô tả</label>
                                    <input type="text" className={`form-control ${errStyle.moTa ? 'is-invalid' : ''}`} id="moTa"
                                        placeholder="Nhập mô tả"
                                        onChange={handleChange}
                                        value={inforNhomSPUpdate.moTa}
                                    />
                                    <div className="invalid-feedback">{errStyle.moTa}</div>
                                </div>
                            </>
                        )}
                        {typeModal === "delete" && (
                            <p>Bạn có chắc chắn muốn xóa nhóm sản phẩm này không?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        {typeModal === "delete" ? (
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Xóa nhóm sản phẩm</button>
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleSave}>
                                {typeModal === "create" ? "Thêm nhóm sản phẩm" : "Lưu thay đổi"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalNhomSP;

import React, { useEffect, useState } from 'react';
import { URL_SERVER_API } from '../appConfig';
import ModalSysInfor from '../components/Modal/ModalSysInfor';
import BillReview from '../components/Bill/BillReview';
import BillConfigForm from './BillConfigForm';import useFormatDate from '../hooks/useFormatDate';


const QuanLyCauHinhSys = () => {
  const [cuahang, setCuaHang] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [configBill, setConfigBill] = useState(null);
  const formatDate = useFormatDate()


  useEffect(() => {
    async function getDuLieu() {
      try {
        const response = await fetch(`${URL_SERVER_API}/v1/sys/docsysInfor`, { method: 'GET' });
        if (!response.ok) throw new Error('Network response was not ok');
        const resultJson = await response.json();


        const result = await fetch(`${URL_SERVER_API}/v1/tk/layThongTinTK/${resultJson.nguoiCapNhatCuoi}`, { method: 'GET' });
        const thongTinUser = await result.json()

        if (thongTinUser.status === "successfully") {
          const nguoiUpdate = thongTinUser.value.ttTaiKhoan[0].hoVaTen + " - " + thongTinUser.value.ttTaiKhoan[0].tenDangNhap
          resultJson.nguoiCapNhatCuoi = nguoiUpdate
          resultJson.thoiGianCapNhatCuoi = formatDate(resultJson.thoiGianCapNhatCuoi)
        }
        setCuaHang(resultJson);
      } catch (error) {
        console.error('Error fetching store information:', error);
      }
    }
    getDuLieu();
  }, [reload]);

  useEffect(() => {
    async function getDuLieu() {
      try {
        const response = await fetch(`${URL_SERVER_API}/v1/sys/docsetupbill`, { method: 'GET' });
        if (!response.ok) throw new Error('Network response was not ok');
        const resultJson = await response.json();
        setConfigBill(resultJson);
      } catch (error) {
        console.error('Error fetching bill configuration:', error);
      }
    }
    getDuLieu();
  }, [reload]);

  const handleCapNhat = () => {
    setShowModal(true);
  };

  if (!cuahang) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="my-4">
        {/* Thông tin Cửa Hàng */}
        <div className="card shadow-sm">
          <div className="card-body">
            <div className="row align-items-center">
              {/* Logo Cửa Hàng */}
              <div className="col-12 col-md-3 text-center mb-3 mb-md-0">
                {cuahang.logoBase64 ? (
                  <img
                    src={cuahang.logoBase64}
                    alt="Logo Cửa Hàng"
                    className="img-fluid rounded-circle"
                    style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                  />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-secondary text-white rounded-circle"
                    style={{ width: '150px', height: '150px' }}
                  >
                    No Logo
                  </div>
                )}
              </div>
              {/* Thông Tin Cửa Hàng */}
              <div className="col-12 col-md-9">
                <h2 className="mb-2">{cuahang.tenCuahang}</h2>
                <p className="text-muted mb-1">{cuahang.diaChiCuahang}</p>
                <div className="row mt-3">
                  <div className="col-12 col-md-6 mb-2">
                    <p className="mb-1"><strong>Mã số thuế:</strong> {cuahang.maSoThue}</p>
                    <p className="mb-1"><strong>Số điện thoại:</strong> {cuahang.sdtCuuaHang}</p>
                  </div>
                  <div className="col-12 col-md-6 mb-2">
                    <p className="mb-1"><strong>Người cập nhật cuối:</strong> {cuahang.nguoiCapNhatCuoi}</p>
                    <p className="mb-1"><strong>Thời gian cập nhật cuối:</strong> {new Date(cuahang.thoiGianCapNhatCuoi).toLocaleString()}</p>
                  </div>
                </div>
                {/* Nút Cập Nhật Thông Tin */}
                <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-primary" onClick={handleCapNhat}>
                    Cập nhật thông tin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Cấu hình và Xem lại Hóa đơn */}
        <div className="row mt-5">
          <div className="col-12 col-lg-6 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <BillConfigForm configBill={configBill} onUpdateConfig={(config) => setConfigBill(config)} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-4">
            <div className="card h-100">
              <div className="card-body">
                <BillReview configBill={configBill} configInfo={cuahang} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Cập Nhật Thông Tin */}
      {showModal && (
        <ModalSysInfor
          onClose={() => setShowModal(false)}
          onReload={() => setReload(!reload)}
          inforCuahang={cuahang}
        />
      )}
    </>
  );
};

export default QuanLyCauHinhSys;

import React, { useState, useEffect } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalKieuThanhToan = ({
    onClose = () => { },
    onReload = () => { },
    inforKieuTT = {}, // Dữ liệu thông tin kiểu thanh toán
    typeModal = "create" // create, update, delete
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenKieuTT: '',
        accountNumber: ''
    });
    
    const [useBankAccount, setUseBankAccount] = useState(inforKieuTT?.soTaiKhoan ? true : false);
    const [banks, setBanks] = useState([]); // State lưu danh sách các ngân hàng
    const [loadingBanks, setLoadingBanks] = useState(true); // Trạng thái tải API ngân hàng

    const [inforKieuTTUpdate, setInforKieuTTUpdate] = useState(typeModal !== "create" && {
        maKieuTT: inforKieuTT.maKieuTT || '',
        accountHolder: inforKieuTT.chuTaiKhoan || '',
        accountNumber: inforKieuTT.soTaiKhoan || '',
        bank: inforKieuTT.nganHang || '',
        tenKieuTT: inforKieuTT.tenKieuTT || '',
        noiDung: inforKieuTT.noiDung || ''
    });

    // Gọi API lấy danh sách ngân hàng từ VietQR
    useEffect(() => {
        const fetchBanks = async () => {
            try {
                const response = await fetch('https://api.vietqr.io/v2/banks');
                const data = await response.json();
                if (data.code === "00") {
                    setBanks(data.data); // Lưu danh sách ngân hàng vào state
                }
            } catch (error) {
                showToast('error', 'Không thể tải danh sách ngân hàng.');
            } finally {
                setLoadingBanks(false); // Hoàn thành tải
            }
        };
        fetchBanks();
    }, [showToast]);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforKieuTTUpdate({ ...inforKieuTTUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const handleCheckboxChange = (e) => {
        setUseBankAccount(e.target.checked);
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforKieuTTUpdate.tenKieuTT) {
            errors.tenKieuTT = 'Vui lòng nhập tên kiểu thanh toán';
            valid = false;
        }

        // Nếu "Dùng tài khoản ngân hàng" được chọn, kiểm tra các trường liên quan
        if (useBankAccount) {
            if (!inforKieuTTUpdate.accountHolder) {
                errors.accountHolder = 'Vui lòng nhập chủ tài khoản';
                valid = false;
            }

            if (!inforKieuTTUpdate.accountNumber) {
                errors.accountNumber = 'Vui lòng nhập số tài khoản';
                valid = false;
            }

            if (!inforKieuTTUpdate.bank) {
                errors.bank = 'Vui lòng chọn ngân hàng';
                valid = false;
            }
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const endpoint = typeModal === "create" ? `${URL_SERVER_API}/v1/ktt/create` : `${URL_SERVER_API}/v1/ktt/update/${inforKieuTTUpdate.maKieuTT}`;
        const method = typeModal === "create" ? "POST" : "PUT";

        const result = await fetch(endpoint, {
            method: method,
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenKieuTT: inforKieuTTUpdate.tenKieuTT,
                noiDung: inforKieuTTUpdate.noiDung,
                accountHolder: useBankAccount ? inforKieuTTUpdate.accountHolder : '',
                accountNumber: useBankAccount ? inforKieuTTUpdate.accountNumber : '',
                bank: useBankAccount ? inforKieuTTUpdate.bank : ''
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', `${typeModal === "create" ? 'Thêm' : 'Cập nhật'} kiểu thanh toán thành công!`);
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    tenKieuTT: resultJson.status === "TKTTDTT" ? "Tên kiểu thanh toán đã tồn tại" : prevErr.tenKieuTT
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    const handleDelete = async () => {
        const result = await fetch(`${URL_SERVER_API}/v1/ktt/delete/${inforKieuTTUpdate.maKieuTT}`, {
            method: "DELETE",
            headers: HEADER_TOKEN_API(token),
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', 'Xóa kiểu thanh toán thành công!');
                onClose();
                onReload();
            } else {
                showToast('error', 'Xóa kiểu thanh toán thất bại!');
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinKT" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">
                            {typeModal === "create" ? "Thêm kiểu thanh toán mới" : typeModal === "update" ? "Cập nhật kiểu thanh toán" : "Xóa kiểu thanh toán"}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {typeModal !== "delete" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="tenKieuTT" className="form-label">Tên kiểu thanh toán</label>
                                    <input type="text" className={`form-control ${errStyle.tenKieuTT ? 'is-invalid' : ''}`} id="tenKieuTT"
                                        placeholder="Nhập tên kiểu thanh toán"
                                        onChange={handleChange}
                                        value={inforKieuTTUpdate.tenKieuTT}
                                    />
                                    <div className="invalid-feedback">{errStyle.tenKieuTT}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="noiDung" className="form-label">Nội dung</label>
                                    <textarea className="form-control" id="noiDung"
                                        placeholder="Nhập nội dung"
                                        onChange={handleChange}
                                        value={inforKieuTTUpdate.noiDung}
                                    />
                                </div>
                                
                                {/* Checkbox Dùng tài khoản ngân hàng */}
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" id="useBankAccount" 
                                        checked={useBankAccount} 
                                        onChange={handleCheckboxChange} 
                                    />
                                    <label className="form-check-label" htmlFor="useBankAccount">
                                        Dùng tài khoản ngân hàng
                                    </label>
                                </div>

                                {/* Các trường liên quan đến tài khoản ngân hàng, chỉ hiển thị khi checkbox được chọn */}
                                {useBankAccount && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="accountHolder" className="form-label">Chủ tài khoản</label>
                                            <input type="text" className={`form-control ${errStyle.accountHolder ? 'is-invalid' : ''}`} id="accountHolder"
                                                placeholder="Nhập tên chủ tài khoản"
                                                onChange={handleChange}
                                                value={inforKieuTTUpdate.accountHolder}
                                            />
                                            <div className="invalid-feedback">{errStyle.accountHolder}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="accountNumber" className="form-label">Số tài khoản</label>
                                            <input type="text" className={`form-control ${errStyle.accountNumber ? 'is-invalid' : ''}`} id="accountNumber"
                                                placeholder="Nhập số tài khoản"
                                                onChange={handleChange}
                                                value={inforKieuTTUpdate.accountNumber}
                                            />
                                            <div className="invalid-feedback">{errStyle.accountNumber}</div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="bank" className="form-label">Ngân hàng</label>
                                            {loadingBanks ? (
                                                <p>Đang tải danh sách ngân hàng...</p>
                                            ) : (
                                                <select className="form-select" id="bank" onChange={handleChange} value={inforKieuTTUpdate.bank}>
                                                    <option value="">Chọn ngân hàng</option>
                                                    {banks.map((bank) => (
                                                        <option key={bank.id} value={bank.shortName}>
                                                            {bank.shortName} - {bank.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                            <div className="invalid-feedback">{errStyle.bank}</div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {typeModal === "delete" && (
                            <p>Bạn có chắc chắn muốn xóa kiểu thanh toán này không?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        {typeModal === "delete" ? (
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Xóa kiểu thanh toán</button>
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleSave}>
                                {typeModal === "create" ? "Thêm kiểu thanh toán" : "Lưu thay đổi"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalKieuThanhToan;

import React from 'react';

const BillReview = ({ configBill = {
    showLogo: false,
    showTaxCode: false,
    showCashierName: false,
    showCustomerInfo: false,
    showOptional1: false,
    optionalContent1: "",
    showOptional2: true,
    optionalContent2: "",
    showQRCode: false,
    selectedAccount: ""
}, configInfo = {
    logoBase64: "",
    tenCuahang: "",
    sdtCuuaHang: "",
    diaChiCuahang: "",
    maSoThue: ""
}
}) => {
    return (
        <div className="bill border p-3 screen-print position-relative" style={{ width: '80mm', fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
            <span className='position-absolute top-0 mt-1 start-0 ms-2 opacity-75'>hd-xxxxxxxx-xxx</span>
            <span className='position-absolute top-0 mt-1 end-0 me-2 opacity-75'>Lần in <span>1</span></span>
            {/* Logo */}
            {configBill?.showLogo && (
                <div className="logo text-center">
                    <img src={configInfo.logoBase64 || ''} alt="Logo cửa hàng" style={{ maxWidth: '70px', height: 'auto' }} />
                </div>
            )}

            {/* Thông tin cửa hàng */}
            <div className="store-info text-center mt-2">
                <h5 className="mb-1" style={{ fontSize: '14px' }}>{configInfo.tenCuahang}</h5>
                <p className="mb-1">SĐT: {configInfo.sdtCuuaHang}</p>
                <p className="mb-1">Địa chỉ: {configInfo.diaChiCuahang}</p>
                {configBill?.showTaxCode && <p className="mb-1">Mã số thuế: {configInfo.maSoThue}</p>}
            </div>

            {/* Thời gian và mã hóa đơn */}
            <div className="text-left mt-2">
                <p className="mb-1">Thời gian in: <strong>{new Date().toLocaleString()}</strong></p>
                {/* <p className="mb-1">Mã hóa đơn: <strong>#xxxxxxxxx</strong></p> */}
                <p className="mb-1">VT: <strong>Bàn xx - Khu vực xx</strong></p>
                {configBill?.showCashierName && <p className="mb-1">Nhân viên: <strong>{configBill?.cashierName}</strong></p>}
                {configBill?.showCustomerInfo && (
                    <div>
                        <p className="mb-1">SĐT KH: <strong>{configBill?.customerPhone}</strong></p>
                        <p className="mb-1">Tên KH: <strong>{configBill?.customerName}</strong></p>
                    </div>
                )}
            </div>

            {/* Tiêu đề hóa đơn */}
            <div className="text-center mt-3">
                <h6 style={{ borderTop: '1px solid black', borderBottom: '1px solid black', padding: '5px', margin: '0' }}>HÓA ĐƠN</h6>
            </div>

            {/* Bảng sản phẩm */}
            <div className="product-list mt-2">
                <table className="w-100">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Sản phẩm</th>
                            <th style={{ textAlign: 'right' }}>Đơn giá</th>
                            <th style={{ textAlign: 'center' }}>SL</th>
                            <th style={{ textAlign: 'right' }}>Thành tiền</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Đây là ví dụ, có thể thay đổi theo dữ liệu */}
                        <tr>
                            <td>xxxxxxxxxx</td>
                            <td style={{ textAlign: 'right' }}>xxx,xxx đ</td>
                            <td style={{ textAlign: 'center' }}>x</td>
                            <td style={{ textAlign: 'right' }}>xxx,xxx đ</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr><td colSpan="4" style={{ textAlign: 'right' }}>Tổng hoá đơn: xxxxx đ</td></tr>
                        <tr><td colSpan="4" style={{ textAlign: 'right' }}>Chiết khấu: xxxxx đ</td></tr>
                        <tr><td colSpan="4" style={{ textAlign: 'right' }}>Tổng sau chiết khấu: xxxxx đ</td></tr>
                        {configBill?.showTienDu && (
                            <>
                                <tr><td colSpan="4" style={{ textAlign: 'right' }}>Tiền khách đưa: xxxxx đ</td></tr>
                                <tr><td colSpan="4" style={{ textAlign: 'right' }}>Tiền trả khách: xxxxx đ</td></tr>
                            </>
                        )}

                    </tfoot>
                </table>
            </div>

            {/* Nội dung tùy chọn */}
            <div className="thank-you mt-3">
                {configBill?.showOptional1 && <p className="mb-1 text-center">{configBill?.optionalContent1}</p>}
                {configBill?.showOptional2 && <p className="mb-1 text-center">{configBill?.optionalContent2}</p>}
            </div>

            {/* QR code */}
            {configBill?.showQRCode && (
                <div className="qr-code text-center mt-2">
                    <img src="\images\istockphoto-1095468750-2048x2048.jpg" alt="QR Code chuyển khoản" style={{ maxWidth: '150px', height: 'auto' }} />
                </div>
            )}
        </div>
    );
};

export default BillReview;

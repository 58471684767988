import React, { useState, useEffect } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalBan = ({
    onClose = () => { },
    onReload = () => { },
    inforBan = {}, // Dữ liệu thông tin bàn
    typeModal = "create" // create, update, delete
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenBan: '',
        ghiChu: '',
        maKhuVuc: ''
    });
    const [inforBanUpdate, setInforBanUpdate] = useState(
        typeModal !== "create"
            ? {
                maBan: inforBan.maBan || '',
                tenBan: inforBan.tenBan || '',
                ghiChu: inforBan.ghiChu || '',
                maKhuVuc: inforBan.maKhuVuc || ''
            }
            : {
                maBan: '',
                tenBan: '',
                ghiChu: '',
                maKhuVuc: ''
            }
    );

    const [khuVucList, setKhuVucList] = useState([]);

    useEffect(() => {
        // Gọi API để lấy danh sách khu vực khi component mount
        const fetchKhuVucList = async () => {
            try {
                const response = await fetch(`${URL_SERVER_API}/v1/kv/danhsach`, {
                    method: "GET",
                    headers: HEADER_TOKEN_API(token),
                });
                const data = await response.json();
                if (data.status === 'successfully') {
                    setKhuVucList(data.value.data);
                } else {
                    showToast('error', 'Lấy danh sách khu vực thất bại');
                }
            } catch (error) {
                showToast('error', error.message);
            }
        };

        fetchKhuVucList();
    }, [token, showToast]);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforBanUpdate({ ...inforBanUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforBanUpdate.tenBan) {
            errors.tenBan = 'Vui lòng nhập tên bàn';
            valid = false;
        }
        if (!inforBanUpdate.maKhuVuc) {
            errors.maKhuVuc = 'Vui lòng chọn khu vực';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const endpoint = typeModal === "create" ? `${URL_SERVER_API}/v1/ban/create` : `${URL_SERVER_API}/v1/ban/update/${inforBanUpdate.maBan}`;
        const method = typeModal === "create" ? "POST" : "PUT";

        const result = await fetch(endpoint, {
            method: method,
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenBan: inforBanUpdate.tenBan,
                ghiChu: inforBanUpdate.ghiChu,
                maKhuVuc: inforBanUpdate.maKhuVuc
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', `${typeModal === "create" ? 'Thêm' : 'Cập nhật'} bàn thành công!`);
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    tenBan: resultJson.status === "TBDTT" ? "Tên bàn đã tồn tại" : prevErr.tenBan
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    const handleDelete = async () => {
        const result = await fetch(`${URL_SERVER_API}/v1/ban/delete/${inforBanUpdate.maBan}`, {
            method: "DELETE",
            headers: HEADER_TOKEN_API(token),
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', 'Xóa bàn thành công!');
                onClose();
                onReload();
            } else {
                showToast('error', 'Xóa bàn thất bại!');
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinBan" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">
                            {typeModal === "create" ? "Thêm bàn mới" : typeModal === "update" ? "Cập nhật thông tin bàn" : "Xóa bàn"}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {typeModal !== "delete" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="tenBan" className="form-label">Tên bàn</label>
                                    <input type="text" className={`form-control ${errStyle.tenBan ? 'is-invalid' : ''}`} id="tenBan"
                                        placeholder="Nhập tên bàn"
                                        onChange={handleChange}
                                        value={inforBanUpdate.tenBan}
                                    />
                                    <div className="invalid-feedback">{errStyle.tenBan}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="maKhuVuc" className="form-label">Khu vực</label>
                                    <select className={`form-control ${errStyle.maKhuVuc ? 'is-invalid' : ''}`} id="maKhuVuc"
                                        onChange={handleChange}
                                        value={inforBanUpdate.maKhuVuc}>
                                        <option value="">Chọn khu vực</option>
                                        {khuVucList.map(kv => (
                                            <option key={kv.maKhuVuc} value={kv.maKhuVuc}>
                                                {kv.tenKhuVuc}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">{errStyle.maKhuVuc}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ghiChu" className="form-label">Ghi chú</label>
                                    <input type="text" className={`form-control ${errStyle.ghiChu ? 'is-invalid' : ''}`} id="ghiChu"
                                        placeholder="Nhập ghi chú"
                                        onChange={handleChange}
                                        value={inforBanUpdate.ghiChu}
                                    />
                                    <div className="invalid-feedback">{errStyle.ghiChu}</div>
                                </div>
                            </>
                        )}
                        {typeModal === "delete" && (
                            <p>Bạn có chắc chắn muốn xóa bàn này không?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        {typeModal === "delete" ? (
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Xóa bàn</button>
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleSave}>
                                {typeModal === "create" ? "Thêm bàn" : "Lưu thay đổi"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalBan;

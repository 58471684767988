import React, { useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalKhachHang = ({
    onClose = () => { },
    onReload = () => { },
    inforKhach = {}, // Dữ liệu thông tin khách hàng
    typeModal = "create" // create, update, delete
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenKhachHang: '',
        sdtKhachHang: ''
    });
    const [inforKhachUpdate, setInforKhachUpdate] = useState(typeModal!=="create"&&{
        maKhach: inforKhach.maKhach || '',
        tenKhachHang: inforKhach.tenKhach || '',
        sdtKhachHang: inforKhach.sdt || ''
    });

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforKhachUpdate({ ...inforKhachUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforKhachUpdate.tenKhachHang) {
            errors.tenKhachHang = 'Vui lòng nhập tên khách hàng';
            valid = false;
        }

        if (!inforKhachUpdate.sdtKhachHang) {
            errors.sdtKhachHang = 'Vui lòng nhập số điện thoại khách hàng';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const endpoint = typeModal === "create" ? `${URL_SERVER_API}/v1/kh/create` : `${URL_SERVER_API}/v1/kh/update/${inforKhachUpdate.maKhach}`;
        const method = typeModal === "create" ? "POST" : "PUT";

        const result = await fetch(endpoint, {
            method: method,
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenKhachHang: inforKhachUpdate.tenKhachHang,
                sdtKhachHang: inforKhachUpdate.sdtKhachHang
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', `${typeModal === "create" ? 'Thêm' : 'Cập nhật'} khách hàng thành công!`);
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    sdtKhachHang: resultJson.status === "SDTDTT" ? "Số điện thoại đã tồn tại" : prevErr.sdtKhachHang
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    const handleDelete = async () => {
        const result = await fetch(`${URL_SERVER_API}/v1/kh/delete/${inforKhachUpdate.maKhach}`, {
            method: "DELETE",
            headers: HEADER_TOKEN_API(token),
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', 'Xóa khách hàng thành công!');
                onClose();
                onReload();
            } else {
                showToast('error', 'Xóa khách hàng thất bại!');
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinKH" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">
                            {typeModal === "create" ? "Thêm khách hàng mới" : typeModal === "update" ? "Cập nhật thông tin khách hàng" : "Xóa khách hàng"}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {typeModal !== "delete" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="tenKhachHang" className="form-label">Tên khách hàng</label>
                                    <input type="text" className={`form-control ${errStyle.tenKhachHang ? 'is-invalid' : ''}`} id="tenKhachHang"
                                        placeholder="Nhập tên khách hàng"
                                        onChange={handleChange}
                                        value={inforKhachUpdate.tenKhachHang}
                                    />
                                    <div className="invalid-feedback">{errStyle.tenKhachHang}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="sdtKhachHang" className="form-label">Số điện thoại</label>
                                    <input type="text" className={`form-control ${errStyle.sdtKhachHang ? 'is-invalid' : ''}`} id="sdtKhachHang"
                                        placeholder="Nhập số điện thoại"
                                        onChange={handleChange}
                                        value={inforKhachUpdate.sdtKhachHang}
                                    />
                                    <div className="invalid-feedback">{errStyle.sdtKhachHang}</div>
                                </div>
                            </>
                        )}
                        {typeModal === "delete" && (
                            <p>Bạn có chắc chắn muốn xóa khách hàng này không?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        {typeModal === "delete" ? (
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Xóa khách hàng</button>
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleSave}>
                                {typeModal === "create" ? "Thêm khách hàng" : "Lưu thay đổi"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalKhachHang;

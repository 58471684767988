import React, { useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalSysInfor = ({
    onClose = () => { },
    onReload = () => { },
    inforCuahang = {} // Dữ liệu thông tin cửa hàng
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenCuahang: '',
        sdtCuuaHang: ''
    });
    const [inforCuahangUpdate, setInforCuahangUpdate] = useState({
        tenCuahang: inforCuahang.tenCuahang || '',
        diaChiCuahang: inforCuahang.diaChiCuahang || '',
        sdtCuuaHang: inforCuahang.sdtCuuaHang || '',
        maSoThue: inforCuahang.maSoThue || '',
        logoBase64: inforCuahang.logoBase64 || ''
    });
    const [imagePreview, setImagePreview] = useState(inforCuahang.logoBase64 || '');

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforCuahangUpdate({ ...inforCuahangUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setInforCuahangUpdate((prev) => ({ ...prev, logoBase64: reader.result }));
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforCuahangUpdate.tenCuahang) {
            errors.tenCuahang = 'Vui lòng nhập tên cửa hàng';
            valid = false;
        }

        if (!inforCuahangUpdate.sdtCuuaHang) {
            errors.sdtCuuaHang = 'Vui lòng nhập số điện thoại cửa hàng';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const result = await fetch(`${URL_SERVER_API}/v1/sys/ghithongtin`, {
            method: 'POST',
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenCuahang: inforCuahangUpdate.tenCuahang,
                diaChiCuahang: inforCuahangUpdate.diaChiCuahang,
                sdtCuuaHang: inforCuahangUpdate.sdtCuuaHang,
                maSoThue: inforCuahangUpdate.maSoThue,
                logoBase64: inforCuahangUpdate.logoBase64
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === 'successfully') {
                showToast('success', 'Thông tin hệ thống đã được cập nhật thành công!');
                onClose();
                onReload();
            } else {
                showToast('error', 'Cập nhật thông tin thất bại!');
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinCuahang" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">
                            Cập nhật thông tin hệ thống
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="tenCuahang" className="form-label">Tên cửa hàng</label>
                            <input type="text" className={`form-control ${errStyle.tenCuahang ? 'is-invalid' : ''}`} id="tenCuahang"
                                placeholder="Nhập tên cửa hàng"
                                onChange={handleChange}
                                value={inforCuahangUpdate.tenCuahang}
                            />
                            <div className="invalid-feedback">{errStyle.tenCuahang}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="diaChiCuahang" className="form-label">Địa chỉ cửa hàng</label>
                            <input type="text" className="form-control" id="diaChiCuahang"
                                placeholder="Nhập địa chỉ cửa hàng"
                                onChange={handleChange}
                                value={inforCuahangUpdate.diaChiCuahang}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sdtCuuaHang" className="form-label">Số điện thoại</label>
                            <input type="text" className={`form-control ${errStyle.sdtCuuaHang ? 'is-invalid' : ''}`} id="sdtCuuaHang"
                                placeholder="Nhập số điện thoại"
                                onChange={handleChange}
                                value={inforCuahangUpdate.sdtCuuaHang}
                            />
                            <div className="invalid-feedback">{errStyle.sdtCuuaHang}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="maSoThue" className="form-label">Mã số thuế</label>
                            <input type="text" className="form-control" id="maSoThue"
                                placeholder="Nhập mã số thuế"
                                onChange={handleChange}
                                value={inforCuahangUpdate.maSoThue}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="logoBase64" className="form-label">Chọn logo</label>
                            <input type="file" className="form-control" id="logoFile" accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="mb-3">
                            {imagePreview && (
                                <img src={imagePreview} alt="Logo Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        <button type="button" className="btn btn-primary" onClick={handleSave}>
                            Lưu thay đổi
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSysInfor;

import React from 'react';
import { toast } from 'react-toastify';
import { Bounce } from 'react-toastify'; // import Bounce transition

const useShowToast = () => {
    const showToast = (type, title) => {
        const options = {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        };

        switch(type) {
            case 'success':
                toast.success(title, options);
                break;
            case 'error':
                toast.error(title, options);
                break;
            case 'warning':
                toast.warning(title, options);
                break;
            case 'info':
            default:
                toast.info(title, options);
                break;
        }
    };

    return showToast;
};

export default useShowToast;

import React from 'react'
import QuanLyKhuVuc from './QuanLyKhuVuc'
import QuanLyBan from './QuanLyBan'

const QuanLyKhuVucBan = () => {
    return (
        <div className='row p-2 bg-body-secondary'>
            <div className='col-md-5'>
                <QuanLyKhuVuc />
            </div>
            <div className='col-md-7'>
                <QuanLyBan />
            </div>
        </div>
    )
}

export default QuanLyKhuVucBan
import React, { useEffect, useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';

const ChiTietDatHang = ({ maHoaDonSort = "", ban = {}, order = {}, onReloadOrder = true, sendReload = () => { } }) => {
  const [valueOrder, setValueOrder] = useState([]);
  const [contextMenu, setContextMenu] = useState({ isVisible: false, x: 0, y: 0, selectedItem: null });
  const token = useTokenAccount()
  // const [maHoaDonSortSet, setMaHoaDonSortSet] = useState(maHoaDonSort);
  // useEffect(() => {
  //   async function layDuLieu() {
  //     try {
  //       const maTam = await fetch(`${URL_SERVER_API}/v1/thungan/laymatam/${ban.maBan}`, { method: 'GET' });
  //       if (maTam.ok) {
  //         const maTamJson = await maTam.json()
  //         if (maTamJson) {
  //           setMaHoaDonSortSet(maTamJson)
  //         }
  //         else {
  //           setMaHoaDonSortSet(maHoaDonSort)
  //         }
  //       }
  //       else {
  //         setMaHoaDonSortSet(maHoaDonSort)
  //       }
  //     } catch (error) {
  //       setMaHoaDonSortSet(maHoaDonSort)
  //     }
  //   }
  //   layDuLieu()
  // }, [ban])


  useEffect(() => {
    const layDuLieuBan = async () => {
      try {
        const result = await fetch(`${URL_SERVER_API}/v1/thungan/layordercuaban/${ban.maBan}`, { method: 'GET' });
        const resultJson = await result.json();

        if (resultJson.status === "successfully") {
          const orderList = resultJson.value.listOrder;

          if (orderList.length > 0) {
            const orderTemp = new Map();

            orderList.forEach(order => {
              const existingOrder = orderTemp.get(order.maSanPham);
              if (existingOrder) {
                existingOrder.soLuong += order.soLuong;
              } else {
                orderTemp.set(order.maSanPham, { ...order });
              }
            });

            // Convert Map thành mảng để sử dụng sau đó
            const orderArray = Array.from(orderTemp.values());
            setValueOrder(orderArray);
          }
          else {
            setValueOrder([]);
          }
        }
      } catch (error) {
        console.error("Lỗi khi lấy dữ liệu:", error);
      }
    };

    if (ban && ban.maBan) {
      layDuLieuBan();
    }
  }, [ban, onReloadOrder]);

  // Hàm để xử lý tăng số lượng sản phẩm
  const handleIncrease = async (order) => {
    // Gọi API để cập nhật số lượng sản phẩm
    await fetch(`${URL_SERVER_API}/v1/thungan/capNhatSoLuong`, {
      method: 'POST',
      headers: HEADER_TOKEN_API(token),
      body: JSON.stringify({
        sl: parseInt(order.soLuong) + 1,
        maChiTiet: order.maChiTiet
      })
    });
    sendReload()
    // Cập nhật trạng thái
    setValueOrder(prevOrders =>
      prevOrders.map(item =>
        item.maSanPham === order.maSanPham ? { ...item, soLuong: item.soLuong + 1 } : item
      )
    );
  };

  // Hàm để xử lý giảm số lượng sản phẩm
  const handleDecrease = async (order) => {
    // Kiểm tra nếu số lượng sản phẩm đã là 1 hoặc nhỏ hơn
    if (order.soLuong <= 1) {
      alert("Số lượng sản phẩm không thể nhỏ hơn 1");
      return;
    }

    // Gọi API để cập nhật số lượng sản phẩm
    await fetch(`${URL_SERVER_API}/v1/thungan/capNhatSoLuong`, {
      method: 'POST',
      headers: HEADER_TOKEN_API(token),
      body: JSON.stringify({
        sl: parseInt(order.soLuong) - 1,
        maChiTiet: order.maChiTiet
      })
    });

    sendReload()

    // Cập nhật trạng thái
    setValueOrder(prevOrders =>
      prevOrders.map(item =>
        item.maSanPham === order.maSanPham
          ? { ...item, soLuong: item.soLuong - 1 }
          : item
      )
    );
  };

  const handleUpdateSoLuong = async (value, order) => {
    const chiTiet = order.maChiTiet;

    // Kiểm tra nếu giá trị là chuỗi rỗng (người dùng xóa trống input)
    if (value === "") {
      // Chỉ cập nhật state nhưng không gọi API
      setValueOrder(prevOrders =>
        prevOrders.map(item =>
          item.maSanPham === order.maSanPham
            ? { ...item, soLuong: "" } // Đặt soLuong thành chuỗi rỗng
            : item
        )
      );
      return; // Không gọi API khi input trống
    }

    // Kiểm tra nếu giá trị chỉ chứa ký tự số
    if (!/^\d+$/.test(value)) {
      // Nếu không phải số, không làm gì
      return;
    }

    // Chuyển đổi giá trị input thành số
    const numericValue = parseInt(value, 10);

    // Kiểm tra nếu giá trị là số nguyên hợp lệ và lớn hơn 0
    if (Number.isInteger(numericValue) && numericValue > 0) {
      // Cập nhật state
      setValueOrder(prevOrders =>
        prevOrders.map(item =>
          item.maSanPham === order.maSanPham
            ? { ...item, soLuong: numericValue }
            : item
        )
      );

      // Gọi API để cập nhật số lượng trên server
      await fetch(`${URL_SERVER_API}/v1/thungan/capNhatSoLuong`, {
        method: 'POST',
        headers: HEADER_TOKEN_API(token),
        body: JSON.stringify({
          sl: numericValue,
          maChiTiet: chiTiet
        })
      });
      sendReload()
    } else {
      // Nếu giá trị không hợp lệ, cũng chỉ cập nhật state mà không gọi API
      setValueOrder(prevOrders =>
        prevOrders.map(item =>
          item.maSanPham === order.maSanPham
            ? { ...item, soLuong: numericValue } // Cập nhật số lượng trong state
            : item
        )
      );
    }
  };

  const handleUpdateGhiChu = async (value, order) => {

    await fetch(`${URL_SERVER_API}/v1/thungan/capnhaghichu`, {
      method: 'PUT',
      headers: HEADER_TOKEN_API(token),
      body: JSON.stringify({
        maChiTiet: order.maChiTiet,
        ghiChuSP: value
      })
    });

    setValueOrder(prevOrders =>
      prevOrders.map(item =>
        item.maSanPham === order.maSanPham
          ? { ...item, ghiChuSP: value }
          : item
      )
    );
  }

  const handleOnBlur = (value, item) => {
    // Chuyển đổi giá trị input thành số nguyên
    const numericValue = parseInt(value, 10);

    // Kiểm tra nếu giá trị là rỗng hoặc nhỏ hơn 1
    if (value === "" || numericValue < 1 || isNaN(numericValue)) {
      // Nếu điều kiện thỏa mãn, đặt lại số lượng thành 1
      setValueOrder(prevOrders =>
        prevOrders.map(order =>
          order.maSanPham === item.maSanPham
            ? { ...order, soLuong: 1 }
            : order
        )
      );
    }
  };

  // 
  const handleContextMenu = (event, item) => {
    event.preventDefault(); // Ngăn không cho mở menu mặc định của trình duyệt
    setContextMenu({
      isVisible: true,
      x: event.clientX,
      y: event.clientY,
      selectedItem: item
    });
  };

  const handleDoubleClick = (event, item) => {
    setContextMenu({
      isVisible: true,
      x: event.clientX,
      y: event.clientY,
      selectedItem: item
    });
  };

  const handleLongClick = (event, item) => {
    setContextMenu({
      isVisible: true,
      x: event.clientX,
      y: event.clientY,
      selectedItem: item
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ isVisible: false, x: 0, y: 0, selectedItem: null });
  };

  const handleDelete = async (contextMenu) => {
    await fetch(`${URL_SERVER_API}/v1/thungan/xoachitetorder/${contextMenu.maChiTiet}`, {
      method: 'DELETE',
      headers: HEADER_TOKEN_API(token)
    });

    if (valueOrder.length - 1 <= 0) {
      await fetch(`${URL_SERVER_API}/v1/thungan/capnhattrangthai`, {
        method: 'PUT',
        headers: HEADER_TOKEN_API(token),
        body: JSON.stringify({
          maBan: ban.maBan,
          trangThai: 1
        })
      });
    }
    sendReload()
  }

  return (
    <div>
      {ban.tenBan && (<strong>{ban.tenBan} - {ban.tenKhuVuc}</strong>)}
      <div>
        {ban.tenBan && (
          <div>
            <strong>Mã hóa đơn: </strong>{maHoaDonSort}
          </div>
        )}
      </div>

      <div className='mt-1' style={{ height: '350px', overflowY: 'auto' }}>
        <table className="table table-hover" style={{ position: 'relative' }}>
          <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
            <tr>
              <th scope="col" style={{ width: '20px' }}>Stt</th>
              <th scope="col" style={{ minWidth: '120px' }}>Tên Sp</th>
              <th scope="col" style={{ width: '200px' }} className='hidden-lg'>đvt</th>
              <th scope="col" style={{ width: '120px' }}>Đg</th>
              <th scope="col" className='text-center' style={{ width: '120px' }}>Sl</th>
              <th scope="col" style={{ width: '120px' }}>Note</th>
            </tr>
          </thead>
          <tbody>
            {valueOrder && valueOrder.map((item, index) => {
              const getColor = (trangThai) => {
                switch (trangThai) {
                  case 3: return "table-warning";
                  case 4: return "table-primary";
                  default: return "";
                }
              };

              return (
                <tr

                  className={getColor(item.trangThaiMon)}
                  key={item.maSanPham}
                  onContextMenu={(e) => {
                    if (item.trangThaiMon !== 4) {
                      handleContextMenu(e, item)
                    }

                  }}
                  onDoubleClick={(e) => {
                    if (item.trangThaiMon !== 4) {
                      handleDoubleClick(e, item)
                    }

                  }}
                  onMouseDown={(e) => {
                    if (item.trangThaiMon !== 4) {
                      if (e.type === 'mousedown' && e.button === 0) {
                        const timer = setTimeout(() => handleLongClick(e, item), 2000);
                        const handleMouseUp = () => {
                          clearTimeout(timer);
                          window.removeEventListener('mouseup', handleMouseUp);
                        };

                        window.addEventListener('mouseup', handleMouseUp);
                      }
                    }
                  }}

                >
                  <th scope="row">{index + 1}</th>
                  <td>{item.tenSanPham}</td>
                  <td className='hidden-lg'>{item.donViTinh}</td>
                  <td>{item.donGia}</td>
                  <td>
                    <div className="input-group mb-3" style={{ width: '100px' }}>
                      <div className="input-group-prepend">
                        <button className="input-group-text" disabled={item.trangThaiMon === 4} onClick={() => handleDecrease(item)} style={{ height: '30px' }}>-</button>
                      </div>
                      <input
                        disabled={item.trangThaiMon === 4}
                        type="text"
                        style={{ width: '30px', height: '30px' }}
                        className="form-control p-1"
                        value={item.soLuong}
                        onChange={(e) => handleUpdateSoLuong(e.target.value, item)}
                        onBlur={(e) => handleOnBlur(e.target.value, item)}
                      />
                      <div className="input-group-prepend">
                        <button disabled={item.trangThaiMon === 4} className="input-group-text" onClick={() => handleIncrease(item)} style={{ height: '30px' }}>+</button>
                      </div>
                    </div>
                  </td>
                  <td >
                    <textarea
                      disabled={item.trangThaiMon === 4}
                      type="text" style={{ border: 'none', outline: 'none', padding: '2px' }} value={item.ghiChuSP} onChange={(e) => { handleUpdateGhiChu(e.target.value, item) }}></textarea> </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {contextMenu.isVisible && (
          <div
            className="context-menu"
            style={{
              position: 'absolute',
              top: `${contextMenu.y}px`,
              left: `${contextMenu.x}px`,
              // backgroundColor: 'white',
              // border: '1px solid #ccc',
              // padding: '10px',
              zIndex: 10,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
            }}
            onMouseLeave={handleCloseContextMenu}
          >
            <button
              className="ui button red"
              onClick={() => {
                handleDelete(contextMenu.selectedItem);
                handleCloseContextMenu();
              }}
            >
              Xóa
            </button>
          </div>
        )}
      </div>

    </div>
  );
};

export default ChiTietDatHang;

import React, { useEffect, useState } from 'react';
import TableElm from '../components/Table/TableElm';
import { URL_SERVER_API } from '../appConfig';
import ModalKhachHang from '../components/Modal/ModalKhachHang';
import ModalSanPham from '../components/Modal/ModalSanPham';
import ModalKhuVuc from '../components/Modal/ModalKhuVuc';

const QuanLyKhuVuc = () => {

    const [reload, setReload] = useState(false)
    const [showModal, setShowModal] = useState('')
    const [valueSelect, setValueSelect] = useState(null)

    const [valueTable, setValueTable] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
    const [totalItems, setTotalItems] = useState(0);

    const optPage = [
        {
            'type': 'update',
            'title': 'Updates'
        },
        {
            'type': 'delete',
            'title': 'Delete'
        },
    ];

    const TableConfig = [
        {
            title: 'Mã khu vực',
            name: 'maKhuVuc',
            hidden: true
        },
        {
            title: 'Tên khu vực',
            name: 'tenKhuVuc',
            hidden: false
        },
        {
            title: 'Ghi chú',
            name: 'ghiChu',
            hidden: false
        }
    ];

    const offset = Math.max((currentPage - 1) * limit, 0);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);

    useEffect(() => {
        async function getValue() {
            const result = await fetch(`${URL_SERVER_API}/v1/kv/filter?limit=${limit}&offset=${offset}&search=${debouncedSearchValue}`, { method: 'GET' });
            const resultJson = await result.json();
            console.log("🚀 ~ getValue ~ resultJson:", resultJson)

            if (resultJson.status === 'successfully') {
                setValueTable(resultJson.value.data);
                setTotalItems(resultJson.value.recordsTotal);
            }
        }

        getValue();
    }, [limit, offset, debouncedSearchValue, reload]);



    // 

    const handleClickBtn = (v, e) => {
        setValueSelect(v)
        setShowModal(e)
    }
    return (

        <>
            <div>
                <button className='ui button m-1 twitter' onClick={() => setShowModal('create')}>Thêm khu vực mới</button>
                <div className='pt-2'>
                    <TableElm
                        optionButton={optPage}
                        TableConfig={TableConfig}
                        valueTable={valueTable}
                        totalItems={totalItems}
                        onHandleClickBtn={(v, e) => { handleClickBtn(v, e) }}
                        onHandleSelectLimit={(e) => { setLimit(e); setCurrentPage(1); }}
                        onHandleChangeTxt={(e) => { setSearchValue(e); }}
                        onHandlePagination={(page) => { setCurrentPage(page); }}
                    />
                </div>
            </div>
            {
                showModal && (
                    <ModalKhuVuc
                        inforKhuVuc={valueSelect}
                        typeModal={showModal}
                        onClose={() => setShowModal('')}
                        onReload={() => setReload(!reload)}
                    />)

            }
        </>

    );
};

export default QuanLyKhuVuc;

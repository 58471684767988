import React, { useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalUpdatePass = ({ onClose = () => { }, inforUser = {}, onReload = () => { } }) => {
    const token = useTokenAccount();
    const showToast = useShowToast();

    const [errStyle, setErrStyle] = useState({
        matKhau: '',
        matKhauNew: '',
        xacNhanMatKhauNew: ''
    });
    const [showPass, setShowPass] = useState({
        matKhau: false,
        matKhauNew: false,
        xacNhanMatKhauNew: false
    });
    const [inforPassUpdate, setInforPassUpdate] = useState({
        matKhau: '',
        matKhauNew: '',
        xacNhanMatKhauNew: ''
    });

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforPassUpdate({ ...inforPassUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforPassUpdate.matKhau) {
            errors.matKhau = 'Vui lòng nhập mật khẩu hiện tại';
            valid = false;
        }

        if (!inforPassUpdate.matKhauNew) {
            errors.matKhauNew = 'Vui lòng nhập mật khẩu mới';
            valid = false;
        } else if (inforPassUpdate.matKhauNew.length < 8) {
            errors.matKhauNew = 'Mật khẩu mới phải có ít nhất 8 ký tự';
            valid = false;
        }

        if (!inforPassUpdate.xacNhanMatKhauNew) {
            errors.xacNhanMatKhauNew = 'Vui lòng xác nhận mật khẩu mới';
            valid = false;
        } else if (inforPassUpdate.matKhauNew !== inforPassUpdate.xacNhanMatKhauNew) {
            errors.xacNhanMatKhauNew = 'Mật khẩu mới và xác nhận không khớp';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const saveUpdate = async () => {
        if (!validateInputs()) {
            return;
        }

        const result = await fetch(`${URL_SERVER_API}/v1/tk/updatepass`, {
            method: "PUT",
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                matKhauNew: inforPassUpdate.matKhauNew,
                xacNhanMatKhauNew: inforPassUpdate.xacNhanMatKhauNew,
                matKhau: inforPassUpdate.matKhau
            })
        });
        const resultJson = await result.json();
        console.log(resultJson)
        try {
            if (resultJson.status === "cntc") {
                showToast('success', 'Cập nhật mật khẩu thành công!');
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    matKhau: resultJson.status === "smk" ? "Sai mật khẩu hiện tại" : prevErr.matKhau
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatMatKhau" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Cập nhật mật khẩu</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="matKhauNew" className="form-label">Mật khẩu mới</label>
                            <div className='input-group'>
                                <input type={showPass.matKhauNew ? "text" : "password"} className={`form-control ${errStyle.matKhauNew ? 'is-invalid' : ''}`} id="matKhauNew"
                                    placeholder="Nhập mật khẩu mới"
                                    onChange={handleChange}
                                />
                                <span className="input-group-text cursor-pointer" >
                                    <i onClick={() => setShowPass({ ...showPass, "matKhauNew": !showPass.matKhauNew })} className={`fa-solid ${showPass.matKhauNew ? 'fa fa-eye' : 'fa-eye-slash'} `}></i>
                                </span>
                            </div>
                            <div className={`${errStyle.matKhauNew && 'd-block'} invalid-feedback`}>{errStyle.matKhauNew}</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="xacNhanMatKhauNew" className="form-label">Xác nhận mật khẩu mới</label>
                            <div className='input-group'>
                                <input type={showPass.xacNhanMatKhauNew ? "text" : "password"} className={`form-control ${errStyle.xacNhanMatKhauNew ? 'is-invalid' : ''}`} id="xacNhanMatKhauNew"
                                    placeholder="Xác nhận mật khẩu mới"
                                    onChange={handleChange}
                                />
                                <span className="input-group-text cursor-pointer" >
                                    <i onClick={() => setShowPass({ ...showPass, "xacNhanMatKhauNew": !showPass.xacNhanMatKhauNew })} className={`fa-solid ${showPass.xacNhanMatKhauNew ? 'fa fa-eye' : 'fa-eye-slash'} `}></i>
                                </span>
                                <div className={`${errStyle.xacNhanMatKhauNew && 'd-block'} invalid-feedback`}>{errStyle.xacNhanMatKhauNew}</div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="matKhau" className="form-label">Mật khẩu hiện tại</label>
                            <div className='input-group'>
                                <input type={showPass.matKhau ? "text" : "password"} className={`form-control ${errStyle.matKhau ? 'is-invalid' : ''}`} id="matKhau"
                                    placeholder="Nhập mật khẩu hiện tại"
                                    onChange={handleChange}
                                />
                                <span className="input-group-text cursor-pointer" >
                                    <i onClick={() => setShowPass({ ...showPass, "matKhau": !showPass.matKhau })} className={`fa-solid ${showPass.matKhau ? 'fa fa-eye' : 'fa-eye-slash'} `}></i>
                                </span>
                            </div>
                            <div className={`${errStyle.matKhau && 'd-block'} invalid-feedback`}>{errStyle.matKhau}</div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        <button type="button" className="btn btn-primary" onClick={saveUpdate}>Lưu thay đổi</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalUpdatePass;

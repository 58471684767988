import React, { useState } from 'react';
import { HEADER_TOKEN_API, URL_SERVER_API } from '../../appConfig';
import useTokenAccount from '../../hooks/useTokenAccount';
import useShowToast from '../../hooks/useShowToast';

const ModalKhuVuc = ({
    onClose = () => { },
    onReload = () => { },
    inforKhuVuc = {}, // Dữ liệu thông tin khu vực
    typeModal = "create" // create, update, delete
}) => {
    const token = useTokenAccount();
    const showToast = useShowToast();
    const [errStyle, setErrStyle] = useState({
        tenKhuVuc: '',
        ghiChu: ''
    });
    const [inforKhuVucUpdate, setInforKhuVucUpdate] = useState(
        typeModal !== "create"
            ? {
                maKhuVuc: inforKhuVuc.maKhuVuc || '',
                tenKhuVuc: inforKhuVuc.tenKhuVuc || '',
                ghiChu: inforKhuVuc.ghiChu || ''
              }
            : {
                maKhuVuc: '',
                tenKhuVuc: '',
                ghiChu: ''
              }
    );
    
    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setInforKhuVucUpdate({ ...inforKhuVucUpdate, [id]: value });

        // Clear lỗi khi người dùng nhập dữ liệu
        setErrStyle((prevErr) => ({ ...prevErr, [id]: '' }));
    };

    const validateInputs = () => {
        let valid = true;
        let errors = {};

        if (!inforKhuVucUpdate.tenKhuVuc) {
            errors.tenKhuVuc = 'Vui lòng nhập tên khu vực';
            valid = false;
        }

        setErrStyle(errors);
        return valid;
    };

    const handleSave = async () => {
        if (!validateInputs()) {
            return;
        }

        const endpoint = typeModal === "create" ? `${URL_SERVER_API}/v1/kv/create` : `${URL_SERVER_API}/v1/kv/update/${inforKhuVucUpdate.maKhuVuc}`;
        const method = typeModal === "create" ? "POST" : "PUT";

        const result = await fetch(endpoint, {
            method: method,
            headers: HEADER_TOKEN_API(token),
            body: JSON.stringify({
                tenKhuVuc: inforKhuVucUpdate.tenKhuVuc,
                ghiChu: inforKhuVucUpdate.ghiChu
            })
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', `${typeModal === "create" ? 'Thêm' : 'Cập nhật'} khu vực thành công!`);
                onClose();
                onReload();
            } else {
                setErrStyle((prevErr) => ({
                    ...prevErr,
                    tenKhuVuc: resultJson.status === "TKVDTT" ? "Tên khu vực đã tồn tại" : prevErr.tenKhuVuc
                }));
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    const handleDelete = async () => {
        const result = await fetch(`${URL_SERVER_API}/v1/kv/delete/${inforKhuVucUpdate.maKhuVuc}`, {
            method: "DELETE",
            headers: HEADER_TOKEN_API(token),
        });
        const resultJson = await result.json();

        try {
            if (resultJson.status === "successfully") {
                showToast('success', 'Xóa khu vực thành công!');
                onClose();
                onReload();
            } else {
                showToast('error', 'Xóa khu vực thất bại!');
            }
        } catch (error) {
            showToast('error', error.message);
        }
    };

    return (
        <div className="modal show d-block bg-secondary bg-opacity-25" id="capNhatThongTinKhuVuc" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">
                            {typeModal === "create" ? "Thêm khu vực mới" : typeModal === "update" ? "Cập nhật thông tin khu vực" : "Xóa khu vực"}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {typeModal !== "delete" && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="tenKhuVuc" className="form-label">Tên khu vực</label>
                                    <input type="text" className={`form-control ${errStyle.tenKhuVuc ? 'is-invalid' : ''}`} id="tenKhuVuc"
                                        placeholder="Nhập tên khu vực"
                                        onChange={handleChange}
                                        value={inforKhuVucUpdate.tenKhuVuc}
                                    />
                                    <div className="invalid-feedback">{errStyle.tenKhuVuc}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ghiChu" className="form-label">Ghi chú</label>
                                    <input type="text" className={`form-control ${errStyle.ghiChu ? 'is-invalid' : ''}`} id="ghiChu"
                                        placeholder="Nhập ghi chú"
                                        onChange={handleChange}
                                        value={inforKhuVucUpdate.ghiChu}
                                    />
                                    <div className="invalid-feedback">{errStyle.ghiChu}</div>
                                </div>
                            </>
                        )}
                        {typeModal === "delete" && (
                            <p>Bạn có chắc chắn muốn xóa khu vực này không?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Đóng</button>
                        {typeModal === "delete" ? (
                            <button type="button" className="btn btn-danger" onClick={handleDelete}>Xóa khu vực</button>
                        ) : (
                            <button type="button" className="btn btn-primary" onClick={handleSave}>
                                {typeModal === "create" ? "Thêm khu vực" : "Lưu thay đổi"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalKhuVuc;

import React, { useState } from 'react';
import { URL_SERVER_API } from '../appConfig';
import { useIsLogin } from '../contexts/IsLoginContext';

const Login = () => {
    const { login } = useIsLogin();
    const [showPass, setShowPass] = useState(false)
    const [errStyle, setErrStyle] = useState('')
    const [data, setData] = useState({
        tenDangNhap: "",
        matKhau: ""
    })
    const handleChangeVL = (e) => {
        const id = e.target.id
        const value = e.target.value
        setData({ ...data, [id]: value })
        setErrStyle('')
    }

    const handleLogin = async () => {
        console.log(data);

        // Validate input data
        if (!data.tenDangNhap) {
            console.log('Username is required');
            return;
        }

        if (!data.matKhau) {
            console.log('Password is required');
            return;
        }

        try {
            console.log(`${URL_SERVER_API}/v1/tk/login`)
            // Make the POST request
            const result = await fetch(`${URL_SERVER_API}/v1/tk/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'  // Make sure to set the content type
                },
                body: JSON.stringify({
                    tenDangNhap: data.tenDangNhap,
                    matKhau: data.matKhau
                })
            });

            // Check if the request was successful
            if (!result.ok) {
                throw new Error('Login failed');
            }

            // Get the response data
            const responseData = await result.json();

            if (responseData.status === "dntc") {
                const token = responseData.value.token
                login(token)


                return
            }
            setErrStyle(responseData.status)

            console.log('Login successful', responseData);

        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <div className='d-flex justify-content-center mt-5'>
            <div className='card p-4 shadow-lg' style={{ maxWidth: '400px', width: '100%' }}>
                <h4 className='card-title text-center mb-4'>Đăng nhập</h4>
                <div className="form-floating mb-3">
                    <input type="text" className={errStyle === "tdnktt" ? "form-control is-invalid" : "form-control "} id="tenDangNhap" onChange={handleChangeVL} placeholder="Tên đăng nhập" />
                    <label htmlFor="tenDangNhap">{errStyle === "tdnktt" ? "Tên đăng nhập không tồn tại" : "Tên đăng nhập"}</label>
                </div>
                <div className="form-floating">
                    <input type={showPass ? "text" : "password"} className={errStyle === "smk" ? "form-control is-invalid" : "form-control "} onChange={handleChangeVL} id="matKhau" placeholder="Password" />
                    <label htmlFor='matKhau'>{errStyle === "smk" ? "Không đúng mật khẩu" : "mật khẩu"}</label>
                    <div className="invalid-feedback"></div>
                </div>
                <div className="form-check  mb-3">
                    <input type="checkbox" onClick={() => setShowPass(!showPass)} className="form-check-input" id="showPass" />
                    <label className="form-check-label" htmlFor="showPass">Hiển thị mật khẩu</label>
                </div>
                <div className='d-grid'>
                    <button className='btn btn-primary' onClick={handleLogin}>Đăng nhập</button>
                </div>
            </div>
        </div>
    );
}

export default Login;

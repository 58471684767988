import React, { useEffect, useState } from 'react'
import { URL_SERVER_API } from '../../appConfig';
import useShowToast from '../../hooks/useShowToast';

const DanhSachMon = ({maHoaDonSort = "", onOrderMon = () => { }, isBanDaChon = false, ban = {} }) => {
  const showToast = useShowToast()
  const [find, setFind] = useState('')
  const [valueSanPham, setValueSanPham] = useState([])
  const [monSelect, setMonSelect] = useState('')
  const [dataOrder, setDataOrder] = useState({
    ghiChuSP: "",
    soLuongSP: 1
  })
  useEffect(() => {
    async function getValue() {
      const result = await fetch(`${URL_SERVER_API}/v1/sp/filter?limit='5000'&offset='0'&search=${find}`, { method: 'GET' });
      const resultJson = await result.json();
      if (resultJson.status === 'successfully') {
        const data = resultJson.value.data

        const groups = []
        data.forEach(item => {
          const list = groups[item.tenNhomSanPham]
          if (list) {
            list.push(item)
          }
          else {
            groups[item.tenNhomSanPham] = [item]

          }

        });

        const groupData = Object.entries(groups)

        setValueSanPham(groupData)
      }
    }
    getValue();
  }, [find]);


  return (
    <div>
      <div className='input-group mt-1 mb-1'>
        <input type="search" value={find} onChange={(e) => setFind(e.target.value)} placeholder='tìm kiếm ....' className='form-control' />
      </div>
      <button className='ui button mini purple'>Thêm móm nhanh</button>
      <div>
        {ban.tenBan && (<strong>{ban.tenBan} - {ban.tenKhuVuc}</strong>)}
      </div>
      <ul className="list-group mt-1" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        {
          valueSanPham.map(([nhomSP, arrMon], index) => {
            return (
              <div key={index}>
                <li className="list-group-item active" aria-current="true">{nhomSP}</li>
                {
                  arrMon.map((mon, chiMuc) => {
                    return (
                      <li key={chiMuc} onClick={() => {
                        if (!isBanDaChon) {
                          showToast('warning', 'Hãy chọn một bàn để tiếp tục')
                        }
                        setMonSelect(mon.maSanPham)
                      }} className={`list-group-item cursor-pointer list-hover ${monSelect === mon.maSanPham && 'list-active'}`}>{mon.tenSanPham}</li>
                    )
                  })
                }
              </div>
            )
          })
        }

      </ul>
      <div className="input-group mt-3">
        <input type="text" value={dataOrder.ghiChuSP} onChange={(e) => {
          setDataOrder({ ...dataOrder, "ghiChuSP": e.target.value })
        }} className="form-control" placeholder="Ghi chú" />
        <input value={dataOrder.soLuongSP} style={{ maxWidth: '60px' }} onChange={(e) => {
          setDataOrder({ ...dataOrder, 'soLuongSP': e.target.value })
        }} type="text" placeholder='SL' className="form-control" />
        <button
          className='ui button mini green'
          onClick={() => {
            if (ban.trangThai === 4) {
              showToast("error", "bàn đã in hóa đơn không thể thêm mới!");
              return;
            }
            if (!monSelect) {
              showToast("warning", "Vui lòng chọn một món!");
            } else if (dataOrder.soLuongSP < 1) {
              showToast("warning", "Số lượng phải lớn hơn !");
            } else {
              onOrderMon(dataOrder, monSelect);
              setDataOrder({
                ghiChuSP: "",
                soLuongSP: 1
              })
            }
          }}
        >
          Order
        </button>

      </div>
    </div>
  )
}

export default DanhSachMon